import './CollapsableSection.css';
import { useState } from 'react';


export const CollapsableSection = (props) => {
    const [expanded, setExpanded] = useState(true);

    function handleClick() {
        if (props.collapsable) {
            setExpanded(!expanded);
        }
    }

    return (
        <div style={props.style} className={`CollapsableSection ${expanded?"expanded":""} ${props.collapsable?"collapsable":""} ${props.className?props.className:""}`}>
            <div className="CollapsableSection__top-container" onClick={handleClick}>
                <h1 className="CollapsableSection__title">{props.title}</h1>
                {props.collapsable?<span className="CollapsableSection__arrow" />:""}
            </div>

            <div className="CollapsableSection__content">
                {props.children}
            </div>
        </div>
    )
}

