import './FloorPlanEditor__BackgroundImageSection.css';
import { CollapsableSection } from '../../../../components/CollapsableSection';
import { useState, useRef } from 'react';


export const FloorPlanEditor__BackgroundImageSection = (props) => {
    const inputRef = useRef();
    const [imageDataUrl, setImageDataUrl] = useState();

    function handleButtonClick() {
        inputRef.current.click();
    }

    function renderImage() {
        setImageDataUrl(URL.createObjectURL(inputRef.current.files[0]))
    }


    return (
        <div className="FloorPlanEditor__BackgroundImageSection">
            <CollapsableSection title="Background Image" collapsable>
                <div className="FloorPlanEditor__BackgroundImageSection__container">
                    <img alt="Selected file preview" className="FloorPlanEditor__BackgroundImageSection__image" src={imageDataUrl} />
                    <button className="FloorPlanEditor__BackgroundImageSection__select-image-button" onClick={handleButtonClick}>Select Image</button>         
                    <input ref={inputRef} type="file" onChange={renderImage} style={{display: "none"}} />
                </div>
            </CollapsableSection>
        </div>
    )
}

