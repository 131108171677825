import './marketing.css'

export const MeetTheTeam = () => {
    return (
        <div className='m_meet_the_team_section' id='about'>
            <p>Meet The Team</p>
            <div className='m_meet_the_team_imgs'>
                <div className='m_team_member'>
                    <img src="./john.jpg" alt="John Watkins Img"></img>
                    <p>John Watkins</p>
                </div>
                <div className='m_team_member'>
                    <img src="./ben.jpg" alt="Ben McDonald Img"></img>
                    <p>Ben McDonald</p>
                </div>
                <div className='m_team_member'>
                    <img src="./joe.png" alt="Joe Williams Img"></img>
                    <p>Joe Williams</p>
                </div>
                <div className='m_team_member'>
                    <img src="./noa.png" style={{objectFit:"cover"}} alt="Noa Sundqvist Img"></img>
                    <p>Noa Sundqvist</p>
                </div>
            </div>
        </div>
    )
}