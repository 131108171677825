import {Link, useLocation, useNavigate} from 'react-router-dom';
import './DeviceMetrics.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Dropdown, Checkbox } from 'semantic-ui-react'
import { useState,useEffect } from 'react';
import {Collapse} from 'react-collapse';
import SlidingPane from "react-sliding-pane";
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import "react-sliding-pane/dist/react-sliding-pane.css";

export const DeviceMetrics = () => {
    const [isOpened, setOpened] = useState({});
    const [batteries, setBatteries] = useState({})
    const [floors, setFloors] = useState([]);
    const [renderList, setRenderList] = useState([])
    const [displayedAlerts, setDisplayedAlerts] = useState([]);
    const [alerts,setAlerts] = useState([]);
    const [paneOpened,openPane] = useState();
    const [filters, setFilters] = useState({})
    const { search } = useLocation();
    const parameters = new URLSearchParams(search);
    const buildingId = parameters.get('building');
    const navigate = useNavigate();
    const [buildingOptions,setBuildingOptions] = useState([])
    let tempAlerts = []
    useEffect(() => {
        async function makeFunctionCall(){
            setRenderList(["esff"])
            openPane(false)
            fetch(`${process.env.REACT_APP_API_URL}/buildingsInSameOrg/${buildingId}`) //getting list of all buildings in same org for dropdown
                .then(res => res.json())
                .then(data => {
                    let tempList = []
                    for (const i in data){
                        tempList.push({"text":data[i][1],"key":data[i][0],"value":data[i][0]})
                    }
                    setBuildingOptions(tempList)
                })
            fetch(`${process.env.REACT_APP_API_URL}/floorsList/${buildingId}`) //getting table data
                .then(res => res.json())
                .then(data => {
                    let tempDict = {}
                    let batteriesDict = {}
                    for (const i in data) { //for each floor
                        tempDict[data[i][0]] = false;
                        for (const j in data[i][1]){  //for each table
                            if (data[i][1][j][2] == null){ //if never seen
                                batteriesDict[data[i][1][j][0]] = "N/A"
                            } else {
                                batteriesDict[data[i][1][j][0]] = estimatePercentage(data[i][1][j][2])
                            }
                        }
                        for (const j in data[i][2]){ //non-table devices don't have batteries
                            batteriesDict[data[i][2][j][0]] = "N/A"
                        }
                    }
                    setOpened(tempDict)
                    setFloors(data)
                    setBatteries(batteriesDict)
                })
            fetch(`${process.env.REACT_APP_API_URL}/alerts/${buildingId}`) //getting all alerts
                .then(res => res.json())
                .then(data => {
                    setDisplayedAlerts(data)
                    setAlerts(data)
                })
            setFilters({ //set all filters to true so alerts show when first loading page
                red : true,
                yellow : true,
                1 : true,
                2 : true,
                3 : true,
                4 : true,
                5 : true,
                fakeFilter : false
            })
                
        }
        makeFunctionCall()
        
    }, [buildingId]);
    function setNewOpened(id){ //sets opened but bodged to make first uses easier
        if (!(id in isOpened)){
            setOpened({...isOpened, [id]: false})
        } else {
            setOpened({...isOpened, [id]: !(isOpened[id])})
        }
    }
    function returnAlertText(alertType) { //returns the text that's displayed on an alert
        let alertText;
        switch(alertType) {
          case 1:
            alertText = "Maintenance needed soon";
            break;
          case 2:
            alertText = "Tamper detected";
            break;
          case 3:
            alertText = "Battery level low";
            break;
          case 4:
            alertText = "Battery level critical";
            break;
          case 5:
            alertText = "Lost connection";
            break;
          default:
            alertText = "Error";
        }
        return alertText;
    }
    function returnAlertSymbol(alertType) { //returns the symbol for an alert
        switch(alertType) {
          case 1:
            return <FontAwesomeIcon icon={solid("screwdriver-wrench")} className='warningIcon' />
          case 2:
            return <FontAwesomeIcon icon={solid("triangle-exclamation")} className='warningIcon'/>
          case 3:
            return <FontAwesomeIcon icon={solid("battery-quarter")} className='warningIcon' />
          case 4:
            return <FontAwesomeIcon icon={solid("battery-empty")} className='warningIcon' />
          case 5:
            return <FontAwesomeIcon icon={solid("wifi")} className='warningIcon' />
          default:
            return <FontAwesomeIcon icon={solid("bug")} className='warningIcon' />
        }
    }
    function returnAlertColour(alertType) { //return the colour of an alert for the corner triangle
        switch(alertType) {
          case 1:
            return "yellow"
          case 2:
            return "red"
          case 3:
            return "yellow"
          case 4:
            return "red"
          case 5:
            return "red"
          default:
            return "red"
        }
    }
    function FloorsSection(){ //all floors
        return (
            <div className='floorsSection'>
                <div className='devicesText'>
                    Devices
                </div>
                <div className='devicesSection scroll'>
                    {floors.map((floor) => { //for each floor
                        return (
                            <FloorRow floor={floor}/> //show a floor
                        )
                    })}
                </div>
            </div>
        )
    }
    function AlertsSection(){ //all alerts
        return (
            <>
                <div className='alertsSection'>
                    <div className='alertsText'>
                        Alerts
                        <FontAwesomeIcon icon={solid('filter')} className='alertFilter' onClick={() => openPane(true)} />
                    </div>
                    <div className='scrollableAlerts scroll'>
                        {displayedAlerts.map((alert) => { //for each alert
                            return (
                                <Alert alert={alert}/> //show an alert
                            )
                        })}
                    </div>
                </div>
            </>
        )
    }
    function Alert({alert}){ //invididual alert
        let colour = returnAlertColour(alert["alertType"])
        return(
            <div className={`alert shadow ${colour}Triangle`}>
                <div className='alertIcon'>
                    {returnAlertSymbol(alert["alertType"])}
                </div>
                <div className='warningBody'>
                    <div className='warningDeviceName'>
                        {alert["floorName"]}, {alert["tableDevice"]}
                    </div>
                    <div className='warningText'>
                        {returnAlertText(alert["alertType"])}
                    </div>
                </div>
                <FontAwesomeIcon icon={solid('xmark')} className='dismissAlert' onClick={() => {
                    dismissAlert(alert["alertId"])
                    recheckAlerts()
                }}/>
            </div>
        )
    }
    function dismissAlert(alertId){ //removes alert from page and marks as dismissed on db
        for (const i in alerts){
            if (alerts[i]["alertId"] === alertId){
                let newAlerts = [...alerts]
                newAlerts.splice(i,1)
                setAlerts(newAlerts)
                tempAlerts = newAlerts
            }
        }
        fetch(`${process.env.REACT_APP_API_URL}/dismissAlert/${alertId}`)
    }
    function returnAngleDirection(id){ //swivel arrow on floor
        if (isOpened[id] === true){
            return faAngleDown
        } else {
            return faAngleRight
        }
    }
    function FloorRow({floor}){ //all floors
        return (
            <> 
                <div className='floor shadow'>
                    <div className='floorTitle' onClick={() => setNewOpened(floor[0])}>
                        <FontAwesomeIcon icon={returnAngleDirection(floor[0])}className={`deviceArrow pen${isOpened[floor[0]]}`}/>
                        {floor[0]}
                    </div>
                    <Collapse isOpened={isOpened[floor[0]]} className=''>
                        <div className='tables scroll'>
                            {floor[1].map((device) => { //show each table
                                return (
                                    <Device device={device}/>
                                )
                            })}
                            {floor[2].map((device) => { //show each non-table device
                                return (
                                    <Device device={device}/>
                                )
                            })}
                        </div>
                    </Collapse>
                </div>
            </>
        )
    }
    function unixToDate(unix){ //converts unix to how long ago
        if (unix == null){
            return "N/A"
        } else {
            const now = new Date();
            const date = new Date(unix * 1000);
            const seconds = Math.floor((now - date) / 1000);
            let interval = Math.floor(seconds / 31536000);
            if (interval >= 1) {
                return interval + " year" + (interval === 1 ? "" : "s") + " ago";
            }
            interval = Math.floor(seconds / 2592000);
            if (interval >= 1) {
                return interval + " month" + (interval === 1 ? "" : "s") + " ago";
            }
            interval = Math.floor(seconds / 86400);
            if (interval >= 1) {
                return interval + " day" + (interval === 1 ? "" : "s") + " ago";
            }
            interval = Math.floor(seconds / 3600);
            if (interval >= 1) {
                return interval + " hour" + (interval === 1 ? "" : "s") + " ago";
            }
            interval = Math.floor(seconds / 60);
            if (interval >= 1) {
                return interval + " minute" + (interval === 1 ? "" : "s") + " ago";
            }
            return Math.floor(seconds) + " second" + (seconds === 1 ? "" : "s") + " ago";
        }
    }
    function recheckAlerts(){ //double checks alerts for after a filter is changed
        let newAlerts = []
        for (const i in tempAlerts){
            let x = tempAlerts[i]['alertType'];
            if (filters[x]){
                newAlerts.push(tempAlerts[i])
            }
        }
        setDisplayedAlerts(newAlerts)
    }
    function changeFilters(filterName,filterState){ //changes which filters are active
        let newAlerts = []
        let newFilters = {...filters};
        for (const i in filterName){
            newFilters[filterName[i]] = filterState[i];
        }
        if (!newFilters[2] && !newFilters[4] && !newFilters[5]){
            newFilters['red'] = false;
        } else{
            newFilters['red'] = true;
        }
        if (!newFilters[1] && !newFilters[3]){
            newFilters['yellow'] = false;
        } else{
            newFilters['yellow'] = true;
        }
        setFilters(newFilters)
        for (const i in alerts){
            let x = alerts[i]['alertType'];
            if (newFilters[x]){
                newAlerts.push(alerts[i])
            }
        }
        setDisplayedAlerts(newAlerts)
        
    }
    function changeColourFilter(colour){ //changes filters if red or yellow toggled
        if (colour === 'red'){
            changeFilters(['red',2,4,5],[!(filters['red']),!(filters['red']),!(filters['red']),!(filters['red'])])
        } else {
            changeFilters(['yellow',1,3],[!(filters['yellow']),!(filters['yellow']),!(filters['yellow'])])
        }
    }
    function estimatePercentage(firstDate){ //figures out how much battery likely left based on a year's estimated life
        const now = new Date();
        const diff = now/1000 - firstDate
        return Math.floor((31536000000 - diff)/315360000)+"%"
    }
    function Device({device}){ //each device
        return(
            <>
                <div className='device'>
                    <div className='deviceName'>
                        {device[0]}
                    </div>
                    <div className='deviceData'>
                        <div className='runningTime'>
                            <FontAwesomeIcon icon={solid('clock')} className='deviceIcon' />
                            {unixToDate(device[1])}
                        </div>
                        <div className='batteryPercentage'>
                            <FontAwesomeIcon icon={solid('battery-half')} className='deviceIcon batteryIcon' />
                            {batteries[device[0]]}
                        </div>
                    </div>
                </div>
            </>
        )
    }
    function BuildingDropdown(){ //dropdown to select building
        return(
            <Dropdown 
                options={buildingOptions}
                selection
                style={{marginBottom:"20px"}}
                defaultValue={parseInt(buildingId)}
                onChange={(_e,data) => navigate("/metrics?building="+data.value)}
                className="dropdown"
            />
        )
    }
    return(
        <>
            <div className='navContainer'>
                <div className='backSection'>
                    <Link to={"/building?building="+buildingId} style={{display:"flex", alignItems:"center", color:"white"}}> 
                        <div className="backArrow">
                            <FontAwesomeIcon icon={solid('angle-left')} />
                        </div>
                        <div className='back'>
                            Back
                        </div>
                    </Link>
                </div>
                {renderList.map((num) => {
                        return (
                            <BuildingDropdown/>
                        )
                    })}
            </div>
            <div className='mainSection'>
                <FloorsSection/>
                <AlertsSection/>
                <SlidingPane //side panel
                isOpen={paneOpened}
                title="Filters"
                width="250px"
                className='filtersPanel'
                onRequestClose={() => {
                    // triggered on "<" on left top click or on outside click
                    openPane(false);
                }}
                >
                    <div className='colourFilters'>
                        <div className='filter'>
                            <Checkbox toggle defaultChecked={filters['red']} checked={filters['red']} onChange={ () =>
                                changeColourFilter('red')
                            }
                            />
                            Red alerts
                        </div>
                        <div className='filter'>
                            <Checkbox toggle defaultChecked={filters['yellow']} checked={filters['yellow']} onChange={ () =>
                                changeColourFilter('yellow')
                            }
                            />
                            Yellow alerts
                        </div>
                    </div>
                    <div className='typeFilters'>
                        <div className='filter'>
                            <Checkbox toggle defaultChecked={filters[2]} checked={filters[2]} onChange={ () =>
                                changeFilters([2],[!(filters[2])])
                            }/>
                            Tamper alerts
                        </div>
                        <div className='filter'>
                            <Checkbox toggle defaultChecked={filters[3]} checked={filters[3]} onChange={ () =>
                                changeFilters([3],[!(filters[3])])
                            }/>
                            Battery low alerts
                        </div>
                        <div className='filter'>
                            <Checkbox toggle defaultChecked={filters[4]} checked={filters[4]} onChange={ () =>
                                changeFilters([4],[!(filters[4])])
                        }/>
                        Battery critical alerts
                        </div>
                        <div className='filter'>
                            <Checkbox toggle defaultChecked={filters[1]} checked={filters[1]} onChange={ () =>
                                changeFilters([1],[!(filters[1])])
                            }/>
                            Maintenance alerts
                        </div>
                        <div className='filter'>
                            <Checkbox toggle defaultChecked={filters[5]} checked={filters[5]} onChange={ () =>
                                changeFilters([5],[!(filters[5])])
                            }/>
                            Connection alerts
                        </div>
                    </div>
                </SlidingPane>
            </div>
        </>
    )
}