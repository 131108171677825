import './FloorPlanEditor__Table.css';
import { useState, useRef, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { ResizableBox } from 'react-resizable';
import { ItemTypes } from '../FloorPlanEditor__dndConstants';
import { FloorMapTable } from '../../../components/FloorMapTable';

export const FloorPlanEditor__Table = ({id, tableNumber, left, top, tables, setEdges, onTableClick=(e)=>{}, onDragging=(id)=>{}, onTableSizeChange=(id, width, height) => {}, selected=false, width=45, height=45, snapLeft, snapRight, snapTop, snapBottom}) => {
    const [ref, setRef] = useState(null);
    const [shiftPressed, setShiftPressed] = useState(false);
    const [size, setSize] = useState({x: width, y:height});

    const resizableBoxRef = useRef();

    const [{isDragging}, drag, preview] = useDrag(() => ({
        type: ItemTypes.TABLE,
        item: { id, left, top, draggedComponent: ref},
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    }), [ref])

    function getStyles(left, top, isDragging) {
        return {
            position: 'absolute',
            // IE fallback: hide the real node using CSS when dragging
            // because IE will ignore our custom "empty image" drag preview.
            opacity: isDragging ? 0 : 1,
            height: isDragging ? 0 : '',
        }
    }

    function dragStart(e){
        getEdges();
        onDragging(id)
    }

    function getEdges(){
        let edges = {x:[], y:[]};
        for (let tableId of Object.keys(tables)){
            if (tableId !== id){
                edges.x.push(tables[tableId].left)
                edges.x.push(tables[tableId].left + tables[tableId].width)

                edges.y.push(tables[tableId].top)
                edges.y.push(tables[tableId].top + tables[tableId].height)
            }
        }
        setEdges(edges)
    }

    function onResize(e) {
        setShiftPressed(e.shiftKey)
        
        function round5(x){
            return Math.ceil(x/5)*5;
        }

        if (shiftPressed){
            if(resizableBoxRef.current.state.width>resizableBoxRef.current.state.height){
                resizableBoxRef.current.state.width = resizableBoxRef.current.state.height
            }else{
                resizableBoxRef.current.state.height = resizableBoxRef.current.state.width
            }
        }

        resizableBoxRef.current.state.width = round5(resizableBoxRef.current.state.width)
        resizableBoxRef.current.state.height = round5(resizableBoxRef.current.state.height)

        setSize({x: resizableBoxRef.current.state.width, y: resizableBoxRef.current.state.height});

        onTableSizeChange(id, resizableBoxRef.current.state.width, resizableBoxRef.current.state.height)
    }

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true })
    }, [preview])

    return (
        <div 
            css-snap-top={snapTop}
            css-snap-bottom={snapBottom}
            css-snap-right={snapRight}
            css-snap-left={snapLeft}
            onDragStart={dragStart} 
            onDragEnd={getEdges} 
            onClick={(e) => {onTableClick(e); e.stopPropagation();}} 
            className={`FloorPlanEditor__Table ${selected?"selected":""}`} 
            key={id} 
            id={id} 
            style={Object.assign({left:left, top:top, cursor: "grab"}, getStyles(left, top, isDragging))}
        >
            <div>
                <ResizableBox ref={resizableBoxRef} width={width} height={height} draggableOpts={{grid: [5, 5]}}
                minConstraints={[45, 45]} maxConstraints={[300, 300]} onResizeStart={onResize} onResize={(e) => {onResize(e)}} lockAspectRatio={shiftPressed}>
                    <div css-size={`${size.x} x ${size.y}`} className="FloorPlanEditor__Table__table-container" ref={(div) => {drag(div);setRef(div);}}>
                        <FloorMapTable
                            id={1}
                            number={tableNumber}
                            x={0}
                            y={0}
                            open={false}
                            available={true}
                            tags={[]}
                            lastSeen={new Date()}
                            clicked={() => {}}
                        />
                    </div>
                </ResizableBox>
            </div>
        </div>
    )
}
