import './popup.css'

export function Popup({open=true, setOpen=()=>{}, children}){
    return (
        <div className={`popup_background ${open?"open":""}`} onPointerDown={()=>{setOpen(false)}}>
            <div className='popup_container' onPointerDown={(e)=>{e.stopPropagation()}}>
                <div className='popup_header'>
                    <p className={"popup_close"} onPointerDown={(e) => {e.stopPropagation()}} onPointerUp={()=>{setOpen(!open)}}>X</p>
                </div>
                {children}
            </div>
        </div>
    )
}