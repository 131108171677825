import './FloorPlanEditor__TableInformationSection.css';
import { CollapsableSection } from '../../../../components/CollapsableSection';
import { FloorPlanEditor__TextInput } from '../../FloorPlanEditor__TextInput';


export const FloorPlanEditor__TableInformationSection = ({table, onNumberChange=(newValue) => {}}) => {

    return (
        <CollapsableSection title="Table Information" collapsable>
            
            {table?
                <div className="FloorPlanEditor__TableInformationSection">
                    <FloorPlanEditor__TextInput label="Table Number: " value={table['tableNumber']} onChange={onNumberChange}/>
                </div>
            :""}
        </CollapsableSection>
    )
}
