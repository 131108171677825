import './FloorPlanEditor__SizeAndPositionSection.css';
import { CollapsableSection } from '../../../../components/CollapsableSection';
import { FloorPlanEditor__TextInput } from '../../FloorPlanEditor__TextInput';


export const FloorPlanEditor__SizeAndPositionSection = ({table, onWidthChange=(newValue) => {}, onHeightChange=(newValue) => {}, onXChange=(newValue) => {}, onYChange=(newValue) => {}, onPositionChange=() => {}}) => {

    return (
        <div className="FloorPlanEditor__SizeAndPositionSection">
            <CollapsableSection title="Size and Position" collapsable>
                {table?
                    <div className="FloorPlanEditor__SizeAndPositionSection__grid-container">
                        <FloorPlanEditor__TextInput label="Width: " value={table['width']} emptyValue={0} onChange={onWidthChange} />
                        <FloorPlanEditor__TextInput label="Height: " value={table['height']} emptyValue={0} onChange={onHeightChange} />
                        <FloorPlanEditor__TextInput label="X: " value={table['left']} emptyValue={0} onChange={onXChange} />
                        <FloorPlanEditor__TextInput label="Y: " value={table['top']} emptyValue={0} onChange={onYChange}/>
                    </div>
                :""}
            </CollapsableSection>
        </div>
    )
}
