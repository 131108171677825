import './FloorMapTableTag.css'


export const FloorMapTableTag = (props) => {

    return (
        <span className={`FloorMapTableTag
            ${(props.children === "Disabled Access")?"disabled":""}
            ${(props.children === "Computer")?"computer":""}
            ${(props.children === "Printer")?"printer":""}`
        }>
            {props.children}
        </span>
    )
}

