import { ReactComponent as Users } from './users.svg'
import { ReactComponent as Lightbulb } from './lightbulb.svg'
import { ReactComponent as Building } from './building.svg'
import './marketing.css'

export const SellingPoints = () => {
    return (
        <div className='m_selling_points'>
            <div className='m_selling_point'>
                <Users style={{height:"50px"}}/>
                <p>Support the shift to flexible working</p>
            </div>
            <div className='m_selling_point'>
                <Lightbulb style={{height:"50px"}}/>
                <p>Real time occupancy insights</p>
            </div>
            <div className='m_selling_point'>
                <Building style={{height:"50px"}}/>
                <p>Optimise your existing spaces</p>
            </div>
        </div>
    )
}