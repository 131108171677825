import './ScaleButtons.css'
import { useRef } from 'react';

export const ScaleButtons = (props) => {
    const containerRef = useRef();


    return (
        <div className="ScaleButtons" ref={containerRef}>
        	<span className="ScaleButtons__button top" onClick={(e) => {props.onUpClick(); e.stopPropagation();}}/>
        	<span className="ScaleButtons__divider" />
        	<span className="ScaleButtons__button bottom" onClick={(e) => {props.onDownClick(); e.stopPropagation();}}/>
        </div>
    )
}

