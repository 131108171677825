import './FloorSelectorButton.css'
import { useState, useEffect, useRef } from 'react';
import { Loader } from '../Loader';

export const FloorSelectorButton = ({className="", paddingBottom=50, selectedFloorId=0, floors=[], onFloorChange=(id) => {}}) => {
    const [selectedFloorName, setSelectedFloorName] = useState("");
    const [opened, setOpened] = useState(false);
    const containerRef = useRef();

    useEffect(() => {
        function handleDocumentClick(e) {
            if (containerRef.current && !containerRef.current.contains(e.target)) {
                // if the click was outside containerRef
                setOpened(false)
            }
        }

        // add click listener to document
        document.addEventListener("mousedown", handleDocumentClick);

        return () => {
            // remove listener on cleanup
            document.removeEventListener("mousedown", handleDocumentClick);
        };
      }, [containerRef,]);

    useEffect(() => {
        // find floor with the id matching selectedFloorId
        let selectedFloor = floors.find(floor => {return floor.id.toString()===selectedFloorId.toString()});
        if (selectedFloor) {
            // set floor name to the floor_name of the selected floor
            setSelectedFloorName(selectedFloor.floor_name);
        }
    }, [selectedFloorId, floors]);

    return (
        <div ref={containerRef} onClick={(e)=>{setOpened(!opened); e.stopPropagation()}} className={`FloorSelectorButton ${className} ${opened?"active":""}`}>
            {selectedFloorName?<h1 className="FloorSelectorButton__selected-floor">{selectedFloorName}</h1>:<Loader type="spin-small"/>}

            <div className="FloorSelectorButton__floors-container" style={{top: paddingBottom}}>

                {floors.map((floor, index) => {
                    return (
                        <span key={index} onClick={() => {onFloorChange(floor.id)}} className={`FloorSelectorButton__floor ${floor.id===selectedFloorId?"selected":""}`}>{floor.floor_name}</span>
                    )
                })}
            </div>
        </div>
    )
}

