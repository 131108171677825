import { Loader } from '../../components/Loader'
import { useState, useEffect, useRef } from 'react';
import './BuildingSummary.css';
import '../../fonts.css';
import { useMediaQuery } from 'react-responsive'
import {useLocation, useNavigate, Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import ScrollContainer from 'react-indiana-drag-scroll'
import { Dropdown } from 'semantic-ui-react'
import { FloorTest } from '../FloorTest';
import useAppName from '../../hooks/useAppName';
import { BuildingSummaryMobileHeader } from './BuildingSummaryMobileHeader';



export const BuildingSummary = () => {

    const [pageData, setPageData] = useState(null);
    const [occupancyData, setOccupancyData] = useState(null);
    const [graphData, setGraphData] = useState(null);
    const [isAdmin, setIsAdmin] = useState(true); //testing with onboarded normal admin
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [isOrgAdmin, setIsOrgAdmin] = useState(false);
    const [isBuildingAdmin, setIsBuildingAdmin] = useState(false);
    const [isGenericUser, setIsGenericUser] = useState(false);
    const [isThirdParty, setIsThirdParty] = useState(false);
    const appName = useAppName();

    const [isAdminView, setIsAdminView] = useState(true);
    const [viewModePopupActive, setViewModePopupActive] = useState(true);

    const [isOnboarded, setIsOnboarded] = useState(true);
    
    const [buildingNameEditSelected, setBuildingNameEditSelected] = useState(false);
    const [openingTimesEditSelected, setOpeningTimesEditSelected] = useState(false)
    const [buildingAddressEditSelected, setBuildingAddressEditSelected] = useState(false)
    const [buildingImageEditSelected, setBuildingImageEditSelected] = useState(false)
    const [buildingFloorEditSelected, setBuildingFloorEditSelected] = useState(false)
    const [buildingNewFloorSelected, setBuildingNewFloorSelected] = useState(false)
    const [selectedFloor, setSelectedFloor] = useState(0)

    const [buildingOpeningTimes, setBuildingOpeningTimes] = useState(null)
    const [currentOpeningTimes, setCurrentOpeningTimes] = useState(null)
    const [openingTimeFromMinute, setOpeningTimeFromMinute] = useState("00")
    const [openingTimeFromHour, setOpeningTimeFromHour] = useState("00")
    const [openingTimeUntilMinute, setOpeningTimeUntilMinute] = useState("00")
    const [openingTimeUntilHour, setOpeningTimeUntilHour] = useState("00")
    const [buildingIsClosed, setBuildingIsClosed] = useState(false)
    const [buildingIsClosedChecked, setBuildingIsClosedChecked] = useState(false)

    const [dataSubmitted, setDataSubmitted] = useState(false)
    const [submissionResponse, setSubmissionResponse] = useState(null)

    //const [buildingNameInput, setBuildingNameInput] = useState(null)
    //const [buildingDescriptionInput, setBuildingDescriptionInput] = useState(null)

    const [imageToUpload, setImageToUpload] = useState(null)
    const [imageToUploadRaw, setImageToUploadRaw] = useState(null)

    //const [buildingIdState, setBuildingIdState] = useState(null)

    const [todayString, setTodayString] = useState(null)

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 850px)' })

    var todayDay = new Date();
    const nowHours = todayDay.getHours()
    const today = todayDay.getDay()
    const [todayInt, setTodayInt] = useState(today)
    const [selectedDay, setSelectedDay] = useState(today);
    //const [editPopupSelectedDay, setEditPopupSelectedDay] = useState(today);

    let navigate = useNavigate();
    const { search } = useLocation();
    const location = useLocation();
    const parameters = new URLSearchParams(search);
    const buildingId = parameters.get('building');

    if (buildingId === null || buildingId === ""){
        navigate('/404');
    }
    
    function OccupancyText({mobile}){
        return (
            occupancyData == null?
            <Loader type={"spin"}/>:
            <>
                <img src="/users.svg" alt="usersIcon" style={mobile?{marginBottom:"5px"}:{}}></img>
                <h2 className="occupancyValue" style={mobile?{lineHeight:"42px"}:{}}>
                    {occupancyData.occupancy}{occupancyData.occupancy_unit}
                </h2>
                <h3 className="occupancyFullText">full</h3>
            </>
        )
    }

    function FloorsSection(){
        return (
            
            <div className='floorSectionContainer'>
                {
                    pageData.obfuscated?
                    <div className={`warningBox`}>
                        <h3>Why can't I see how many tables are free?</h3>
                        <p>For security reasons this organisation has restricted view enabled when the building is under {pageData.org_obfuscation_perc}% occupancy</p>
                    </div>:<></>
                }
                <h3 className={`title ${isTabletOrMobile?"mobile":""}`}>Floors</h3>
                <p className={`floorsSubtitle ${isTabletOrMobile?"mobile":""}`}>Select a floor to find a table</p>
                <div>
                    {pageData.floors.map((floor)=>{
                        return (
                            <FloorRow floor={floor} key={floor.floor_id}/>
                        )
                    })}
                </div>
                {(isAdmin && isAdminView) || (isOrgAdmin && isAdminView) || (isBuildingAdmin && isAdminView) || (isSuperAdmin && isAdminView) ? (
                    <button className="blueBtn" onClick={() => navigate('/floor/newFloor?building='+buildingId,{state:{message:"Floor being added."}})}>+ Add Floor</button>
                ) : <></>}
            </div>
        )
    }

    function PopuplarTimesSection(){
      let maxVal = 0;
      if (graphData !== null){
        maxVal = Math.max(...Object.values(graphData))
      }

      const scroller = useRef(null);

      useEffect(()=>{
        if(scroller.current !== null){
  
          let scrollPos = (nowHours-3) * 40

          if(scrollPos < 0){
            scrollPos = 0
          }

          if (scrollPos > 40*17){
            scrollPos = 40*17
          }

          if(scrollPos !== scroller.current.scrollLeft){
            scroller.current.scrollTo(scrollPos, 0);
          }
        }
      }, [scroller])

      const timeKeys = {
        0:"12am",
        1:"1am",
        2:"2am",
        3:"2am",
        4:"4am",
        5:"5am",
        6:"6am",
        7:"7am",
        8:"8am",
        9:"9am",
        10:"10am",
        11:"11am",
        12:"12pm",
        13:"1pm",
        14:"2pm",
        15:"3pm",
        16:"4pm",
        17:"5pm",
        18:"6pm",
        19:"7pm",
        20:"8pm",
        21:"9pm",
        22:"10pm",
        23:"11pm",
      }

      const options = [
        {"text":"Monday", "key":"Monday", value:1,},
        {"text":"Tuesday", "key":"Tuesday", value:2},
        {"text":"Wednesday", "key":"Wednesday",  value:3},
        {"text":"Thursday", "key":"Thursday", value:4},
        {"text":"Friday", "key":"Friday", value:5},
        {"text":"Saturday", "key":"Saturday", value:6},
        {"text":"Sunday", "key":"Sunday", value:0}
      ]
      
      return (
        <>
          <h3 className={`title ${isTabletOrMobile?"mobile":""}`} style={{marginBottom:"15px"}}>Popular Times</h3>
          <div className={`popularTimesContainer ${isTabletOrMobile?"mobile":""}`}>
            <div style={{width:"fit-content"}}>
              <Dropdown 
                options={options}
                selection
                fluid
                style={{marginBottom:"20px"}} 
                value={selectedDay}
                onChange={(e,data) => {setSelectedDay(data.value); updateDisplayedOpeningTimes(data.value)}}
              />
            </div>
            <div className={`occupancyGraph ${isTabletOrMobile?"mobile":""}`}>
              {
                graphData === null?
                <Loader type={"spin"}/>:
                <div style={{position:"relative", maxWidth:"280px"}}>
                  {
                    <span className='graphMaxVal'>{maxVal}%</span>
                  }
                  <ScrollContainer className="scroll-container" horizontal={true} vertical={false} innerRef={scroller}>
                      <div className="barChartContainer">
                          {
                            [...Array(24).keys()].map((i) => {
                              const val = graphData[i]

                              return (
                                <div className='barChartBarContainer' key={i}>
                                  <div className={`barChartBar ${nowHours===i?"now":""}`} style={{height:`${(100/maxVal)*(val+1)}px`}}></div>
                                  <span className='barChartLine'></span>
                                  <span className='barChartTime'>{timeKeys[i]}</span>
                                </div>
                              )
                            })
                          }
                      </div>
                  </ScrollContainer> 
                </div>
              }
            </div>
          </div>
        </> 
      )
    }

    function InfoSection(){
        return (
            <div className='infoSectionContainer'>
                <div className='infoSectionRow'>
                    <div className="infoIcon">
                        <FontAwesomeIcon icon={solid('layer-group')} />
                    </div>
                    <p className='infoText'>{`Floors: ${pageData.floors.length}`}</p>
                </div>

                <div className='infoSectionRow'>
                    <div className="infoIcon">
                        <FontAwesomeIcon icon={solid('location-dot')} />
                    </div>
                    <p className='infoText'>{pageData.address}</p>
                    {!isThirdParty && isAdminView ? (
                        <button className='editButton' onClick={() => setBuildingAddressEditSelected(true)}>Edit</button>
                    ):<></>}
                </div>

            
                <div className='infoSectionRow'>
                    <p className='infoText'>{`Opening Times: ${currentOpeningTimes}`}</p>
                    {!isThirdParty && isAdminView ? (
                        <button className='editButton' onClick={() => {setOpeningTimesEditSelected(true);/*setEditPopupSelectedDay(selectedDay);*/updateLocalEditTimes();}}>Edit</button>
                    ) :<></>}
                </div>
                
                <a 
                    target={"_blank"} 
                    href={pageData.location_url} 
                    style={{width:"fit-content"}}
                    rel="noreferrer"
                    className={`directionBtn ${isTabletOrMobile?"mobile":""}`}
                >
                    <button className='blueBtn'>
                        Get Directions
                    </button>
                </a>
            </div>
        )
    }

    // function for collecting data from user input fields for general building information
    const handleBuildingEditSubmit = (event) =>{
        event.preventDefault();
        //setBuildingNameInput(event.target.buildingName.value);
        //setBuildingDescriptionInput(event.target.buildingDescription.value);
        setDataSubmitted(true); 
        setBuildingNameEditSelected(false);
        updateBuildingInformation(event.target.buildingName.value, event.target.buildingDescription.value);
    }

    // function to update the image selected by a user being displayed prior to upload
    const updateDisplayImage = (event) => {
        const [file] = event.target.files;
        setImageToUpload(URL.createObjectURL(file));
        setImageToUploadRaw(file);
    }

    // funtion to upload building image to AWS server
    const saveBuildingImage = () => {        
        // For now, only JPG files are retrieved. PNGs and JPGs can be uploaded but converted in S3 to JPG by default.
        const fileEnding = "jpg";
        let imageFileName = "";
        fetch(`${process.env.REACT_APP_API_URL}/getS3URL?buildingId=`+buildingId)
            .then(response => 
                response.json()
            )
            .then(response => {
                imageFileName = "https://occusenseimageuploads.s3.eu-west-2.amazonaws.com/"+String(buildingId)+"/"+String(response.filename);
                fetch(response.URL,{
                    method: "PUT",
                    body: imageToUploadRaw,
                    
                })
                    .then(response => {
                        fetch(`${process.env.REACT_APP_API_URL}/updateBuildingHeaderImage?buildingId=`+String(buildingId)+`&image=`+String(imageFileName)+"."+String(fileEnding),{
                            method: "GET"
                        })
                            .then(response => {
                                response.json()
                                .then(data =>({
                                    data: data,
                                    success: data.success
                                }))
                                .then(res =>{
                                    if(res.data.success){
                                        setSubmissionResponse(res.success)
                                    }else{
                                        setSubmissionResponse("There was an error updating your image.")
                                    }
                                })
                                let tempPageData = pageData;
                                tempPageData.header_img = imageFileName+".jpg";
                                setPageData(tempPageData);                                
                            })
                    })

            })

    }

    // function to update the current opening times on display for a chosen day
    const updateDisplayedOpeningTimes = (weekdayIndex) => {
        const days = [
            "Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"
        ]
        setTodayInt(weekdayIndex)
        let dayOfTheWeek = days[weekdayIndex - 1]
        if(weekdayIndex === 0){
            dayOfTheWeek = days[6]        
        }

        // I can't believe that you are not allowed to use String variables for JSON reference reading...
        if(dayOfTheWeek.toLowerCase() === "monday"){
            setTodayString("Monday")
            setCurrentOpeningTimes(JSON.parse(buildingOpeningTimes).Monday);
            if(JSON.parse(buildingOpeningTimes).Monday.toLowerCase() === "closed"){
                setBuildingIsClosedChecked(true);
            }
        }
        if(dayOfTheWeek.toLowerCase() === "tuesday"){
            setTodayString("Tuesday")
            setCurrentOpeningTimes(JSON.parse(buildingOpeningTimes).Tuesday);
            if(JSON.parse(buildingOpeningTimes).Tuesday.toLowerCase() === "closed"){
                setBuildingIsClosedChecked(true);
            }
        }
        if(dayOfTheWeek.toLowerCase() === "wednesday"){
            setTodayString("Wednesday")
            setCurrentOpeningTimes(JSON.parse(buildingOpeningTimes).Wednesday);
            if(JSON.parse(buildingOpeningTimes).Wednesday.toLowerCase() === "closed"){
                setBuildingIsClosedChecked(true);
            }
        }
        if(dayOfTheWeek.toLowerCase() === "thursday"){
            setTodayString("Thursday")
            setCurrentOpeningTimes(JSON.parse(buildingOpeningTimes).Thursday);
            if(JSON.parse(buildingOpeningTimes).Thursday.toLowerCase() === "closed"){
                setBuildingIsClosedChecked(true);
            }
        }
        if(dayOfTheWeek.toLowerCase() === "friday"){
            setTodayString("Friday")
            setCurrentOpeningTimes(JSON.parse(buildingOpeningTimes).Friday);
            if(JSON.parse(buildingOpeningTimes).Friday.toLowerCase() === "closed"){
                setBuildingIsClosedChecked(true);
            }
        }
        if(dayOfTheWeek.toLowerCase() === "saturday"){
            setTodayString("Saturday")
            setCurrentOpeningTimes(JSON.parse(buildingOpeningTimes).Saturday);
            if(JSON.parse(buildingOpeningTimes).Saturday.toLowerCase() === "closed"){
                setBuildingIsClosedChecked(true);
            }
        }
        if(dayOfTheWeek.toLowerCase() === "sunday"){
            setTodayString("Sunday")
            setCurrentOpeningTimes(JSON.parse(buildingOpeningTimes).Sunday);
            if(JSON.parse(buildingOpeningTimes).Sunday.toLowerCase() === "closed"){
                setBuildingIsClosedChecked(true);
            }
        }
    }
    
    // function to submit building information updates to the database
    const updateBuildingInformation = (buildingName, buildingDescription) =>{
        fetch(`${process.env.REACT_APP_API_URL}/editBuilding/buildingInfo?building=`+buildingId+`&name=`+buildingName+`&description=`+buildingDescription,
        {
            method:'get'
        })
            .then(response => 
                // Take the response and convert it to JSON.
                response.json()
            )    
            .then(response => {
                if(response.error){
                    setSubmissionResponse(response.error)
                }
                else if(response.success){
                    setSubmissionResponse(response.success)
                    let tempPageData = pageData;
                    tempPageData.building_name = buildingName
                    tempPageData.description = buildingDescription
                    setPageData(tempPageData)
                }
                else{
                    setSubmissionResponse("Request failed, try again later.")
                }
            })
    }

    // function to upload basic floor information updates to the database
    const updateFloorInformation = (event) =>{
        event.preventDefault();
        setDataSubmitted(true);
        setBuildingFloorEditSelected(false);

        fetch(`${process.env.REACT_APP_API_URL}/editBuilding/floor?building=`+buildingId+`&floor=`+selectedFloor.floor_id+`&name=`+event.target.buildingFloorName.value+`&description=`+event.target.buildingFloorDescription.value,{
            method: 'GET'
        })
            .then(response => 
                // Take the response and convert it to JSON.
                response.json()
            )    
            .then(response => {
                if(response.failure){
                    setSubmissionResponse(response.error)
                }
                else if(response.success){
                    setSubmissionResponse(response.success);
                    let tempPageData = pageData;
                    let tempFloorsData = tempPageData.floors;
                    tempFloorsData[selectedFloor.floor_id - 1].floor_name = event.target.buildingFloorName.value;
                    tempFloorsData[selectedFloor.floor_id- 1].floor_description = event.target.buildingFloorDescription.value;
                    tempPageData.floors = tempFloorsData;
                    setPageData(tempPageData);
                }
                else{
                    setSubmissionResponse("Request failed, try again later.")
                }
            })
    }

    // function to upload building address information to database
    const updateBuildingAddressInformation = (event) =>{
        event.preventDefault();
        setDataSubmitted(true);
        setBuildingAddressEditSelected(false);

        fetch(`${process.env.REACT_APP_API_URL}/editBuilding/buildingAddress?building=`+buildingId+`&address=`+String(event.target.buildingAddress.value)+`&addressUrl=`+String(event.target.buildingAddressUrl.value),{
            method: 'GET'
        })
            .then(response => 
                // Take the response and convert it to JSON.
                response.json()
            )    
            .then(response => {
                if(response.failure){
                    setSubmissionResponse(response.error)
                }
                else if(response.success){
                    setSubmissionResponse(response.success)
                    let tempPageData = pageData;
                    tempPageData.address = String(event.target.buildingAddress.value);
                    tempPageData.location_url = String(event.target.buildingAddressUrl.value);
                    setPageData(tempPageData);
                }
                else{
                    setSubmissionResponse("Request failed, try again later.")
                }
            })
    }

    function FloorRow({floor}){
        let floor_perc;
        let colour;
        if(floor.table_count === 0){
            floor_perc = 1
        }else{
            floor_perc = (floor.tables_free / floor.table_count) * 100
        }
        
        if (floor_perc > 50){
            colour = "green"
        }else if (floor_perc > 25){
            colour = "orange"
        }else{
            colour = "red"
        }

        if(pageData.obfuscated){
            colour = "green"
        }



        return (
            <Link to={`/floor/${floor.floor_id}`}>
                <div className={`floorRow shadow ${colour}`} key={floor.floor_id}>
                    <p className='floorRowName'>{floor.floor_name}</p>
                    {
                        pageData.obfuscated?
                        <p className='floorRowSubText'>Tables Available</p>:
                        <p className='floorRowSubText'>{`${floor.tables_free}/${floor.table_count} tables free`}</p>
                    }
                    {(isOnboarded && isAdmin && isAdminView) || (isOnboarded && isOrgAdmin && isAdminView) || (isOnboarded && isBuildingAdmin && isAdminView) || (isOnboarded && isSuperAdmin && isAdminView) ?(
                        <button className='blueBtn' onClick={/*() => initialiseFloorToEdit(floor.floor_id)*/(e)=>{e.stopPropagation(); setSelectedFloor(floor); setBuildingFloorEditSelected(true);}}>
                            Edit
                        </button>
                    ) : <></>}
                    <div className="selectRowBtn">
                        <FontAwesomeIcon icon={solid('angle-right')} />
                    </div>
                </div>
            </Link>
        )
    }

    // popup for displaying response message to users
    function SubmissionResponsePopup(){
        return(
            <div className='popup'>
                <div className='popupOverlay shadow'>
                    <div className='backContainerDesktop' onClick={() => {setDataSubmitted(false);setSubmissionResponse("Loading...");}}>
                        <div className="backArrowDesktop">
                            <FontAwesomeIcon icon={solid('angle-left')} />
                        </div>
                        <p className='backTextDesktop'>Back</p>
                    </div>
                    <h1>Response</h1>
                    <h2>{submissionResponse}</h2>                        
                </div>
            </div>  
        )
    }

    // popup for editing building name and description
    function EditBuildingNamePopup(){
        return(
            <div className='popup'>
                {isOnboarded ? (
                <div className='popupOverlay shadow'>
                    <div className='backContainerDesktop' onClick={() => setBuildingNameEditSelected(false)}>
                        
                        <div className="backArrowDesktop">
                            <FontAwesomeIcon icon={solid('angle-left')} />
                        </div>
                        <p className='backTextDesktop'>Back</p>
                    </div>
                    <h1>Building Details</h1>
                    <div className='editForm'>
                        <form onSubmit={handleBuildingEditSubmit}>
                            <label>Name</label>
                            <input name="buildingName" type="text" placeholder={pageData.building_name}/>
                            <label>Description</label>
                            <input name="buildingDescription" type="text" placeholder={pageData.description}/>
                            <div className='formButtonContainer'>
                                <input className='interactButton shadow' type="submit" placeholder='Save'/>
                                <button className='adminButton shadow' onClick={() => setBuildingNameEditSelected(false)}>Cancel</button>    
                            </div>                        
                        </form>
                    </div>    
                </div>    
                ) : 
                <div className='popupOverlay shadow'>
                    <div className='backContainerDesktop' onClick={() => setBuildingNameEditSelected(false)}>
                        <div className="backArrowDesktop">
                            <FontAwesomeIcon icon={solid('angle-left')} />
                        </div>
                        <p className='backTextDesktop'>Back</p>
                    </div>
                    <h1>Building Details</h1>
                    <p>Add Name</p>
                    <div className='buttonContainer'>
                        <button className='interactButton shadow' onClick={() => {updateBuildingInformation();setDataSubmitted(true);setBuildingNameEditSelected(false)}}>Save</button>
                        <button className='adminButton shadow' onClick={() => setBuildingNameEditSelected(false)}>Cancel</button>
                    </div>    
                </div>    
                }
            </div>
        )
    }

    // function to update displayed opening times in the edit popup
    const updateLocalEditTimes = () => {
        if(currentOpeningTimes !== "closed"){
            let currentTimeStringArr = currentOpeningTimes.split("-");
            let openingStringArr = currentTimeStringArr[0].split(":");
            let closingStringArr = currentTimeStringArr[1].split(":");
            setOpeningTimeFromHour(openingStringArr[0]);
            setOpeningTimeFromMinute(openingStringArr[1]);
            setOpeningTimeUntilHour(closingStringArr[0]);
            setOpeningTimeUntilMinute(closingStringArr[1]);
            setBuildingIsClosed(false);
        }else{
            setBuildingIsClosed(true);
        }
    }

    // function to upload opening time changes to the database
    const saveOpeningTimeChanges = () => {
        let openingTimeString = ""
        if(!buildingIsClosed){
            openingTimeString = String(openingTimeFromHour)+":"+String(openingTimeFromMinute)+"-"+String(openingTimeUntilHour)+":"+String(openingTimeUntilMinute);
        }else{
            openingTimeString = "closed";
        }
        fetch(`${process.env.REACT_APP_API_URL}/editBuilding/buildingHours?building=`+buildingId+`&times=`+openingTimeString+`&day=`+todayInt/*today*/,{
            method: 'POST'
        })
            .then(response => 
                response.json()
            )    
            .then(response => {
                if(response.error){
                    setSubmissionResponse(response.error)
                }
                else if(response.success){
                    setSubmissionResponse(response.success);
                    let tempPageData = pageData;
                    let tempOpeningTimes = tempPageData.openingTimes;
                    
                    let tempOpeningTimesBlob = JSON.parse(tempOpeningTimes);
                    tempOpeningTimesBlob[String(todayString)] = String(openingTimeString);
                    tempOpeningTimes = JSON.stringify(tempOpeningTimesBlob);
                    tempPageData.openingTimes = tempOpeningTimes;
                    setPageData(tempPageData);
                    setBuildingOpeningTimes(tempPageData.openingTimes);
                    setCurrentOpeningTimes(openingTimeString);
                }
                else{
                    setSubmissionResponse("Request failed, try again later.")
                }
            })
    }

    // function to update the displayed state of checkbox between user clicks
    const updateBuildingClosedCheckbox = () => {
        if(buildingIsClosedChecked === false){
            setBuildingIsClosedChecked(true)
            setBuildingIsClosed(true)
        }else{
            setBuildingIsClosedChecked(false)
            setBuildingIsClosed(false)
        }
    }

    // container for the user logout button
    /*function AccountTabSection(){
        return(
            <div className='accountTabContainer'>
                <div className='accountTabContent'>
                    <button className='blueBtn' onClick={() => {location.state = "";setIsOnboarded(false);setIsThirdParty(true);}}>Log out</button>
                </div>
            </div>
        )
    }*/

    // popup for editing building opening times
    function EditOpeningTimesPopup(){

        const options = [
            {"text":"Monday", "key":"Monday", value:1,},
            {"text":"Tuesday", "key":"Tuesday", value:2},
            {"text":"Wednesday", "key":"Wednesday",  value:3},
            {"text":"Thursday", "key":"Thursday", value:4},
            {"text":"Friday", "key":"Friday", value:5},
            {"text":"Saturday", "key":"Saturday", value:6},
            {"text":"Sunday", "key":"Sunday", value:0}
          ]

        const hours = [
            {"text":"00","key":"00",value:"00"},
            {"text":"01","key":"01",value:"02"},
            {"text":"03","key":"03",value:"03"},
            {"text":"04","key":"04",value:"04"},
            {"text":"05","key":"05",value:"05"},
            {"text":"06","key":"06",value:"06"},
            {"text":"07","key":"07",value:"07"},
            {"text":"08","key":"08",value:"08"},
            {"text":"09","key":"09",value:"09"},
            {"text":"10","key":"10",value:"10"},
            {"text":"11","key":"11",value:"11"},
            {"text":"12","key":"12",value:"12"},
            {"text":"13","key":"13",value:"13"},
            {"text":"14","key":"14",value:"14"},
            {"text":"15","key":"15",value:"15"},
            {"text":"16","key":"16",value:"16"},
            {"text":"17","key":"17",value:"17"},
            {"text":"18","key":"18",value:"18"},
            {"text":"19","key":"19",value:"19"},
            {"text":"20","key":"20",value:"20"},
            {"text":"21","key":"21",value:"21"},
            {"text":"22","key":"22",value:"22"},
            {"text":"23","key":"23",value:"23"}
        ]

        const minutes = [
            {"text":"00","key":"00",value:"00"},
            {"text":"15","key":"15",value:"15"},
            {"text":"30","key":"30",value:"30"},
            {"text":"45","key":"45",value:"45"}
          ]
        return(
            <div className='popup'>
                {isOnboarded ? (
                    <div className='popupOverlay shadow'>
                        <div className='backContainerDesktop' onClick={() => setOpeningTimesEditSelected(false)}>
                            <div className="backArrowDesktop">
                                <FontAwesomeIcon icon={solid('angle-left')} />
                            </div>
                            <p className='backTextDesktop'>Back</p>
                        </div>
                        <p>Edit Opening Hours</p>
                        <div style={{width:"fit-content"}}>
                            <Dropdown 
                                options={options}
                                selection
                                fluid
                                style={{marginBottom:"20px"}} 
                                value={selectedDay}
                                onChange={(e,data) => {setSelectedDay(data.value); updateDisplayedOpeningTimes(data.value)}}
                            />
                        </div>
                        
                        <p className='editTimesCurrentTimesText'>{currentOpeningTimes}</p>

                        {buildingIsClosedChecked === false ? (
                            <div className='editTimesSectionContainer'>
                                <div className='editTimesSection'>
                                    <label>Open From</label>
                                    <div className='editTimesSegment'>
                                        <Dropdown
                                            options={hours}
                                            selection
                                            fluid
                                            defaultValue={openingTimeFromHour}
                                            onChange={(_e,data) => {setOpeningTimeFromHour(data.value)}}
                                        />
                                        <p>:</p>
                                        <Dropdown 
                                            options={minutes}
                                            selection
                                            fluid
                                            defaultValue={openingTimeFromMinute}
                                            onChange={(_e,data) => {setOpeningTimeFromMinute(data.value)}}
                                        />
                                        
                                    </div>
                                </div>

                                <div className='editTimesSection'>        
                                    <p>-</p>
                                </div>

                                <div className='editTimesSection'>
                                    <label>Open Until</label>
                                    <div className='editTimesSegment'>
                                        <Dropdown
                                            options={hours}
                                            selection
                                            fluid
                                            defaultValue={openingTimeUntilHour}
                                            onChange={(_e,data) => {setOpeningTimeUntilHour(data.value)}}
                                        />
                                        <p>:</p>
                                        <Dropdown 
                                            options={minutes}
                                            selection
                                            fluid
                                            defaultValue={openingTimeUntilMinute}
                                            onChange={(_e,data) => {setOpeningTimeUntilMinute(data.value)}}
                                        />
                                    </div>
                                </div>    
                            </div> 
                        ):<></>}      

                        <div className='checkboxContainer'>
                            <p>Closed</p>
                            {buildingIsClosedChecked ? (
                                <input className="closedCheckbox" type="checkbox" checked={true} onChange={updateBuildingClosedCheckbox}></input>
                            ):<input className="closedCheckbox" type="checkbox" checked={false} onChange={updateBuildingClosedCheckbox}></input>
                            }
                        </div>     

                        <div className='buttonContainer'>
                            <button className='interactButton shadow' onClick={() => {saveOpeningTimeChanges();setDataSubmitted(true);setOpeningTimesEditSelected(false)}}>Save</button>
                            <button className='adminButton shadow' onClick={() => setOpeningTimesEditSelected(false)}>Cancel</button>
                        </div>    
                    </div>    
                ) : 
                <div className='popupOverlay shadow'>
                    <div className='backContainerDesktop' onClick={() => setOpeningTimesEditSelected(false)}>
                        <div className="backArrowDesktop">
                            <FontAwesomeIcon icon={solid('angle-left')} />
                        </div>
                        <p className='backTextDesktop'>Back</p>
                    </div>
                    <p>Add Times</p>
                    <div className='buttonContainer'>
                        <button className='interactButton shadow' onClick={() => {saveOpeningTimeChanges();setDataSubmitted(true);setOpeningTimesEditSelected(false)}}>Save</button>
                        <button className='adminButton shadow' onClick={() => setOpeningTimesEditSelected(false)}>Cancel</button>
                    </div>
                </div>    
                }
            </div>
        )
    }

    // popup for editing building address 
    function EditAddressPopup(){
        return(
            <div className='popup'>
                {isOnboarded ? (
                    <div className='popupOverlay shadow'>
                        <div className='backContainerDesktop' onClick={() => setBuildingAddressEditSelected(false)}>
                            <div className="backArrowDesktop">
                                <FontAwesomeIcon icon={solid('angle-left')} />
                            </div>
                            <p className='backTextDesktop'>Back</p>
                        </div>
                        <p>Edit Address</p>
                        <form className='editForm' onSubmit={updateBuildingAddressInformation}>
                            <label>Address</label>
                            <input name="buildingAddress" type="text" placeholder='default...'></input><br/>
                            <label>Edit Address URL</label>
                            <input name="buildingAddressUrl" type="text" placeholder='default...'></input>
                            <div className='formButtonContainer'>
                                <input className='interactButton shadow' type={'submit'} placeholder="Save"></input>
                                <button className='adminButton shadow' onClick={() => setBuildingAddressEditSelected(false)}>Cancel</button>
                            </div>
                        </form>        
                    </div>    
                ) : 
                <div className='popupOverlay shadow'>
                    <div className='backContainerDesktop' onClick={() => setBuildingAddressEditSelected(false)}>
                        <div className="backArrowDesktop">
                            <FontAwesomeIcon icon={solid('angle-left')} />
                        </div>
                        <p className='backTextDesktop'>Back</p>
                    </div>
                    <p>Add Address</p>
                    <div className='buttonContainer'>
                        <button className='interactButton shadow' onClick={() => {updateBuildingAddressInformation();setDataSubmitted(true);setBuildingAddressEditSelected(false)}}>Save</button>
                        <button className='adminButton shadow' onClick={() => setBuildingAddressEditSelected(false)}>Cancel</button>
                    </div>
                </div>    
                }
            </div>
        )
    }

    // popup for editing basic floor information (name and description)
    function EditFloorPopup(){
        return(
            <div>
                {!isOnboarded ? (
                    //<NewFloorPopup></NewFloorPopup>
                    <></>
                ) :
                    <div className='popup'>
                        <div className='popupOverlay shadow'>
                            <div className='backContainerDesktop' onClick={() => setBuildingFloorEditSelected(false)}>
                                <div className="backArrowDesktop">
                                    <FontAwesomeIcon icon={solid('angle-left')} />
                                </div>
                                <p className='backTextDesktop'>Back</p>
                            </div>
                            <p>Edit floor information</p>
                            <form className="editForm" onSubmit={updateFloorInformation}>
                                <label>Floor Name</label>
                                <input name="buildingFloorName" placeholder={selectedFloor.floor_name}></input><br/>
                                <label>Floor description</label>
                                <input name="buildingFloorDescription" placeholder={selectedFloor.floor_description}></input><br/>
                                <div className='formButtonContainer'>
                                    <button className='adminButton shadow' onClick={() => navigate('/floor/editFloor?building='+buildingId,{state:{message:"Floor being edited."}})}>Edit Tables</button>
                                </div>

                                <div className='formButtonContainer'>
                                    <input type="submit" className='interactButton shadow' placeholder='Save'></input>    
                                    <button className='adminButton shadow' onClick={() => {setBuildingFloorEditSelected(false);}}>Cancel</button>
                                </div>
                            </form>              
                        </div>
                    </div>    
                    }
            </div>    
        )
    }

    // popup for updating building header image
    function EditImagePopup(){
        return(
            <div className='popup'>
                {isOnboarded ? (
                    <div className='popupOverlay shadow'>
                        <div className='backContainerDesktop' onClick={() => setBuildingImageEditSelected(false)}>
                            <div className="backArrowDesktop">
                                <FontAwesomeIcon icon={solid('angle-left')} />
                            </div>
                            <p className='backTextDesktop'>Back</p>
                        </div>
                        <input className='inputTitle' type="file" accept="image/*" placeholder='Select an image' onChange={updateDisplayImage}></input>
                        <div className='imageUploadContainer'>
                            <img src={imageToUpload} alt="Selected building header to upload."></img>
                        </div>    
                        <div className='buttonContainer'>
                            <button className='interactButton shadow' onClick={() => {setDataSubmitted(true);setBuildingImageEditSelected(false); saveBuildingImage();}}>Save</button>
                            <button className='adminButton shadow' onClick={() => setBuildingImageEditSelected(false)}>Cancel</button>
                        </div>    
                    </div>
                ) : 
                <div className='popupOverlay shadow'>
                    <div className='backContainerDesktop' onClick={() => setBuildingImageEditSelected(false)}>
                        <div className="backArrowDesktop">
                            <FontAwesomeIcon icon={solid('angle-left')} />
                        </div>
                        <p className='backTextDesktop'>Back</p>
                    </div>
                    <button>Select an image</button>
                    <div className='buttonContainer'>
                        <button>Select an image</button>
                        <button className='interactButton shadow' onClick={() => {setDataSubmitted(true);setBuildingImageEditSelected(false)}}>Save</button>
                        <button className='adminButton shadow' onClick={() => setBuildingImageEditSelected(false)}>Cancel</button>
                    </div>
                </div>
            }
            </div>
        )
    }

    // notification box for warning admins that changes they make will be immediate
    function AdminWarningMessage(){
        return(
            <div className="adminWarningBox shadow">
                    {isAdminView ? (
                        <div className="adminWarningBoxContent">
                            <p>You are logged in as an admin, any changes made will be deployed immediately. You can switch to standard view.</p>
                            <div className="adminWarningBoxContentButtonContainer">
                                <button className="blueBtn shadow" onClick={toggleIsAdminView}>Switch </button>
                                <button className="redBtn shadow" onClick={hideViewWarningPopup}>Dismiss</button>
                            </div>
                        </div>     
                    ):
                    <div className="adminWarningBoxContent">
                        <p>You are in standard view, you cannot make any changes. You can switch to admin view to make changes.</p>
                        <div className="adminWarningBoxContentButtonContainer">
                            <button className="blueBtn shadow" onClick={toggleIsAdminView}>Switch </button>
                            <button className="redBtn shadow" onClick={hideViewWarningPopup}>Dismiss</button>
                        </div>
                    </div>    
                    }                       
            </div>
        )
    }

    // function to hide admin notification box
    const hideViewWarningPopup = () => {
        setViewModePopupActive(false)
    }

    // function to update whether an admin wants to edit page information or not
    const toggleIsAdminView = () => {
        if(isAdminView === true){
            setIsAdminView(false)
        }else{
            setIsAdminView(true)
            setViewModePopupActive(true)
        }
    }
    
    // This useEffect is initialising user priveleges
    useEffect(()=>{
        if(location.state){
            setIsOnboarded(true)
            if(location.state.permission === 1){
                setIsAdmin(true)
                setIsAdminView(false)
                setViewModePopupActive(false)
            }
            else if(location.state.permission === 2){
                setIsSuperAdmin(true)
                setIsAdminView(false)
                setViewModePopupActive(false)
            }
            else if(location.state.permission === 3){
                setIsOrgAdmin(true)
                setIsAdminView(false)
                setViewModePopupActive(false)                
            }
            else if(location.state.permission === 4){
                setIsBuildingAdmin(true)
                setIsAdminView(false)
                setViewModePopupActive(false)
            }
            else if(location.state.permission === 5){
                setIsGenericUser(true)
            }
        }else{
            setIsThirdParty(true)
            setIsAdmin(false)
            setIsSuperAdmin(false)
            setIsOrgAdmin(false)
            setIsBuildingAdmin(false)
            setIsGenericUser(false)
            setIsAdminView(false)
            setViewModePopupActive(false)
            setIsOnboarded(false)
        }
    }, [location])

    useEffect(()=>{

        async function makeFunctionCall(){
            fetch(`${process.env.REACT_APP_API_URL}/building/${buildingId}`)
                .then(res => res.json())
                .then(data => {
                    setPageData(data);
                    document.title = appName + " | " + data.building_name

                    // Add the opening times JSON blob from the database to state variable
                    setBuildingOpeningTimes(data.openingTimes);

                    // I can't believe that you are not allowed to use String variables for JSON reference reading...
                    if(today === 0){
                        setTodayString("Sunday");
                        setCurrentOpeningTimes(JSON.parse(data.openingTimes).Sunday);
                    }
                    if(today === 1){
                        setTodayString("Monday");
                        setCurrentOpeningTimes(JSON.parse(data.openingTimes).Monday);
                    }
                    if(today === 2){
                        setTodayString("Tuesday");
                        setCurrentOpeningTimes(JSON.parse(data.openingTimes).Tuesday);
                    }
                    if(today === 3){
                        setTodayString("Wednesday");
                        setCurrentOpeningTimes(JSON.parse(data.openingTimes).Wednesday);
                    }
                    if(today === 4){
                        setTodayString("Thursday");
                        setCurrentOpeningTimes(JSON.parse(data.openingTimes).Thursday);
                    }
                    if(today === 5){
                        setTodayString("Friday");
                        setCurrentOpeningTimes(JSON.parse(data.openingTimes).Friday);
                    }
                    if(today === 6){
                        setTodayString("Saturday");
                        setCurrentOpeningTimes(JSON.parse(data.openingTimes).Saturday);
                    }
                })
        }

        makeFunctionCall();
        
    }, [buildingId, today, appName]);

    useEffect(()=>{
        const days = {
            0:"sunday",
            1:"monday",
            2:"tuesday",
            3:"wednesday",
            4:"thursday",
            5:"friday",
            6:"saturday",
            7:"sunday"
        }

        //setBuildingIdState(buildingId);
        
        async function makeFunctionCall(){
            setGraphData(null)
            fetch(`${process.env.REACT_APP_API_URL}/building/${buildingId}/popularity/${days[selectedDay]}`)
                .then(res => res.json())
                .then(data => {
                    for(let key of Object.keys(data)){
                        data[key] = Number.parseInt(data[key])
                    }})

            const data = {
                "0":"8",
                "1":"12",
                "2":"0",
                "3":"0",
                "4":"0",
                "5":"1",
                "6":"3",
                "7":"8",
                "8":"30",
                "9":"37",
                "10":"43",
                "11":"44",
                "12":"38",
                "13":"56",
                "14":"58",
                "15":"67",
                "16":"55",
                "17":"55",
                "18":"48",
                "19":"40",
                "20":"30",
                "21":"20",
                "22":"10",
                "23":"5"
            }

            for(let key of Object.keys(data)){
                data[key] = Number.parseInt(data[key])
            }

            setGraphData(data)
        }

        makeFunctionCall()
    }, [selectedDay, buildingId]);


    useEffect(() => {
        async function makeFunctionCall(){
            fetch(`${process.env.REACT_APP_API_URL}/building/${buildingId}/occupancy/percentage`)
                .then(res => res.json())
                .then(data => setOccupancyData(data))
        }

        makeFunctionCall()
    }, [buildingId]);

    return (
        pageData==null?<div className="fullscreen"><Loader /></div>:
        <>            
            {!isThirdParty && !isGenericUser ? (
                <div>
                    {buildingNewFloorSelected ? (
                        <div>
                            <div className='popup'>
                                <div className='popupOverlay shadow'>
                                    <Link to={`/home?organisation=${pageData.organisationId}`}>
                                        <div className='backContainerDesktop' onClick={() => setBuildingNewFloorSelected(false)}>
                                            <div className="backArrowDesktop">
                                                <FontAwesomeIcon icon={solid('angle-left')} />
                                            </div>
                                            <p className='backTextDesktop'>Back</p>
                                        </div>
                                    </Link>    
                                    <p>Add floor information</p>
                                    <input placeholder='Floor name'></input>
                                    <input placeholder='Floor description'></input>
                                    <input placeholder='Table map'></input>
                                    <div>
                                        <FloorTest></FloorTest>
                                    </div>    
                                    <div className='buttonContainer'>
                                        <button className='interactButton shadow' onClick={() => {updateFloorInformation();setDataSubmitted(true);setBuildingNewFloorSelected(false)}}>Save</button>
                                        <button className='adminButton shadow' onClick={() => setBuildingNewFloorSelected(false)}>Cancel</button>
                                    </div>        
                                </div>
                            </div>    
                        </div>    
                    ) : <></>}

                    {buildingNameEditSelected ? (
                        <EditBuildingNamePopup></EditBuildingNamePopup>
                    ):<></>}

                    {buildingFloorEditSelected ? (
                        <EditFloorPopup></EditFloorPopup>
                    ) : <></>}

                    {buildingImageEditSelected ? (
                        <EditImagePopup></EditImagePopup>
                    ) : <></>}

                    {!openingTimesEditSelected ? (
                        <></>
                    ):<EditOpeningTimesPopup></EditOpeningTimesPopup>}

                    {!buildingAddressEditSelected ? (
                        <></>
                    ) : <EditAddressPopup></EditAddressPopup>}

                    {dataSubmitted ?(
                        <SubmissionResponsePopup></SubmissionResponsePopup>
                    ) : <></>}

                    {isOnboarded?(
                        <div>
                            <div className={`heroContainer ${isTabletOrMobile?"light":""}`}>
                                {
                                    isTabletOrMobile?
                                    <div className="back-arrow-mobile">
                                        <FontAwesomeIcon icon={solid('angle-left')} />
                                    </div>:
                                    <></>
                                }
                                
                                <div className="heroImg" style={{backgroundImage:`url(${pageData.header_img})`}}></div>
                                <h1 className={`buildingName ${isTabletOrMobile?"mobile":""}`}>{pageData.building_name}</h1>
                                {!buildingImageEditSelected && isAdminView ? (
                                    <div>
                                        <button className={"buildingImageEditButton"} onClick={() => setBuildingImageEditSelected(true)}>Upload image</button>
                                    </div>
                                ) : <></>}

                                {!buildingNameEditSelected && isAdminView ? (
                                    <button className={`buildingEditButton ${isTabletOrMobile?"mobile":""}`} onClick={() => setBuildingNameEditSelected(true)}>Edit</button>
                                ):<></>}
                                {
                                    isTabletOrMobile?<></>:
                                    <div className="heroOccupancySection">
                                        <OccupancyText/>
                                    </div>
                                }
                            </div>
                            {viewModePopupActive ? (
                                <AdminWarningMessage></AdminWarningMessage>
                            ):
                            <div>
                                {isAdminView ? (
                                    <div className="nakedAdminViewButtonContainer">
                                        <button className='blueBtn shadow' onClick={toggleIsAdminView}>Switch to standard view</button>
                                    </div>    
                                ) : 
                                <div className="nakedAdminViewButtonContainer">
                                    <button className='blueBtn shadow' onClick={toggleIsAdminView}>Switch to admin view</button>
                                </div>    
                                }
                            </div>
                            }

                            { isBuildingAdmin ||isOrgAdmin || isAdmin || isSuperAdmin ?(
                                <div className="buildingAdminNavBar">
                                    <div className="buildingAdminNavBarButtonContainer">
                                        <button className="buildingAdminNavBarButton" onClick={() => navigate('/metrics?building='+buildingId,{state:{message:"Building page redirect."}})}>metrics</button>
                                        <span className="buildingAdminNavBarButtonDivider">|</span>
                                        <button className="buildingAdminNavBarButton" onClick={() => setIsOnboarded(false)}>logout</button>
                                        {!isBuildingAdmin ? (
                                            <>
                                                <span className="buildingAdminNavBarButtonDivider">|</span>
                                                <button className='buildingAdminNavBarButton'>users</button>
                                            </>
                                        ):<></>}
                                    </div>
                                </div>    
                            ):<></>}
                            {
                                isTabletOrMobile?
                                <main className='mobile'>
                                    <div className='mobileOccupancyText'>
                                        <OccupancyText mobile={true}/>
                                    </div>
                                    <PopuplarTimesSection/>
                                    <hr className={"buildingSummary"}/>
                                    <FloorsSection />
                                    <hr className={"buildingSummary"}/>
                                    <InfoSection/>
                                </main>:
                                <main className='desktop'>
                                    <div className='desktopLeftSection'>
                                        <div className='backContainerDesktop'>
                                            <Link to={`/home?organisation=${pageData.organisationId}`}>
                                                <div className="backArrowDesktop">
                                                    <FontAwesomeIcon icon={solid('angle-left')} />
                                                </div>
                                                <p className='backTextDesktop'>Back</p>
                                            </Link>
                                        </div>
                                        <FloorsSection />
                                    </div>
                                    <div className='desktopRightSection'>
                                        <PopuplarTimesSection/>
                                        <hr className={"buildingSummary"}/>
                                        <InfoSection/>
                                    </div>
                                </main>
                            }

                            
                        </div>
                    ):
                    <div>
                        <h1>You are not onboarded</h1>
                    </div>
                    }
                </div>
            ):
            <div>
                {
                    isTabletOrMobile?
                    <>
                        <BuildingSummaryMobileHeader backTo={`/home?organisation=${pageData.organisationId}`} backgroundImageUrl={pageData.header_img} buildingName={pageData.building_name} />
                    </>
                    :
                
                    <div className={`heroContainer ${isTabletOrMobile?"light":""}`}>
                        {
                            isTabletOrMobile?
                            <div className="back-arrow-mobile">
                                <FontAwesomeIcon icon={solid('angle-left')} />
                            </div>:
                            <></>
                        }
                                    
                        <div className="heroImg" style={{backgroundImage:`url(${pageData.header_img})`}}></div>
                        <h1 className={`buildingName ${isTabletOrMobile?"mobile":""}`}>{pageData.building_name}</h1>
                        {
                            isTabletOrMobile?<></>:
                            <div className="heroOccupancySection">
                                <OccupancyText/>
                            </div>
                        }
                    </div>
                }
                {
                    isTabletOrMobile?
                    <main className='mobile'>
                        <div className='mobileOccupancyText'>
                            <OccupancyText mobile={true}/>
                        </div>
                        <PopuplarTimesSection/>
                        <hr className={"buildingSummary"}/>
                        <FloorsSection />
                        <hr className={"buildingSummary"}/>
                        <InfoSection/>
                    </main>:
                    <main className='desktop'>
                        <div className='desktopLeftSection'>
                            <div className='backContainerDesktop'>
                                <Link to={`/home?organisation=${pageData.organisationId}`} style={{display:"flex", alignItems:"center", color:"black"}}>
                                    <div className="backArrowDesktop">
                                        <FontAwesomeIcon icon={solid('angle-left')} />
                                    </div>
                                    <p className='backTextDesktop'>Back</p>
                                </Link>
                            </div>
                            <FloorsSection />
                        </div>
                        <div className='desktopRightSection'>
                            <PopuplarTimesSection/>
                            <hr className={"buildingSummary"}/>
                            <InfoSection/>
                        </div>
                    </main>
                }
            </div>
            }      
        </>
    );
        
}

