import './marketing.css'
import Logo from './logo.svg'
import { useMediaQuery } from 'react-responsive'

export const NavBar = ({scrollPosition}) => {
    const smallScreen = useMediaQuery({ query: '(max-width: 600px)' })

    return (
        <nav className={`m_nav_bar ${scrollPosition >=10 ? "scrolled":""}`}>
            <a href='#top' className='m_a_tag'>
                <p className='m_brand_name'>occusense</p>
            </a>
            <a href='#top' className='m_a_tag'>
                <img src={Logo} alt="OccuSense Otis Logo"></img>
            </a>
            {
                !smallScreen?(
                    <div className='m_nav_links'>
                        <a href='#about' className='m_a_tag'>
                            <p className='m_nav_link'>about</p>
                        </a>
                        <a href='#contact' className='m_a_tag' style={{marginLeft:"35px"}}>
                            <p className='m_nav_link'>contact</p>
                        </a>
                    </div>
                ):
                <></>
            }
            
        </nav>
    )
}