import { useDragLayer } from 'react-dnd';
import { ItemTypes } from '../FloorPlanEditor__dndConstants';
import { FloorPlanEditor__Table } from '../FloorPlanEditor__Table';

export const FloorPlanEditor__CustomDragLayer = ({edges, snapDistance}) => {
   
    const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }))

    if(item == null){
        return null;
    }

    let local_snaps = {left:false, right:false, top:false, bottom:false};

    const layerStyles = {
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 100,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
    }
   
    let width = 0;
    let height = 0;

    if(itemType === ItemTypes.TABLE){
        if (item != null){
            width = item.draggedComponent.parentElement.style.width
            height = item.draggedComponent.parentElement.style.height
        }
    }

    function getItemStyles(initialOffset, currentOffset) {

        function closestDigit(x, digits) {
          let closest = digits[0];
          let diff = Math.abs(x - closest);
          
          for (let i = 1; i < digits.length; i++) {
            const currentDiff = Math.abs(x - digits[i]);
            if (currentDiff < diff) {
              closest = digits[i];
              diff = currentDiff;
            }
          }
          
          return [closest, diff];
        }

        

        if (!initialOffset || !currentOffset) {
            return {
            display: 'none',
            }
        }
        let { x, y } = currentOffset

        let [xConstOffset, yConstOffset] = [0,0];

        var viewportOffset = document.getElementsByClassName("FloorPlanEditor__Map")[0].getBoundingClientRect();

        xConstOffset += viewportOffset.left
        yConstOffset += viewportOffset.top

        const offsetEdges = {x: edges.x.map((e)=>e+xConstOffset), y: edges.y.map((e)=>e+yConstOffset)}

        


        //x
        if(edges.x.length !== 0){
            let [closest, diff] = closestDigit(currentOffset.x, offsetEdges.x);
            let [closestToWidth, widthDiff] = closestDigit(currentOffset.x + parseInt(width), offsetEdges.x);

            if(diff === widthDiff && diff <= snapDistance){
                local_snaps.left = true
                local_snaps.right = true
            }

            if(diff < widthDiff){
                if(diff <= snapDistance){
                    x = closest
                    local_snaps.left = true
                } 
            } else {
                if(widthDiff <= snapDistance){
                    x = closestToWidth - parseInt(width)
                    local_snaps.right = true
                }
            }
             
        }

        //y
        if(edges.y.length !== 0){
            let [closest, diff] = closestDigit(currentOffset.y, offsetEdges.y);
            let [closestToHeight, heightDiff] = closestDigit(currentOffset.y + parseInt(height), offsetEdges.y);

            if(diff === heightDiff && diff <= snapDistance){
                local_snaps.top = true
                local_snaps.bottom = true
            }

            if(diff < heightDiff){
                if(diff <= snapDistance){
                    y = closest
                    local_snaps.top = true
                } 
            } else {
                if(heightDiff <= snapDistance){
                    y = closestToHeight - parseInt(height)
                    local_snaps.bottom = true
                }
            }
        }

        const transform = `translate(${x}px, ${y}px)`
        return {
            transform,
            WebkitTransform: transform,
        }
            
    }

    function renderItem() {

        switch (itemType) {
            case ItemTypes.TABLE:
                return <FloorPlanEditor__Table 
                            width={parseInt(width)} 
                            height={parseInt(height)} 
                            selected={true}
                            snapRight={local_snaps.right?"true":"false"}
                            snapLeft={local_snaps.left?"true":"false"}
                            snapTop={local_snaps.top?"true":"false"}
                            snapBottom={local_snaps.bottom?"true":"false"}
                        />
            case ItemTypes.TABLE_TOOLBOX:
                return <FloorPlanEditor__Table width={45} height={45} selected/>
            default:
                return null
        }
    }

    if (!isDragging) {
        return null
    }

    return (
        <div style={layerStyles}>
            <div style={getItemStyles(initialOffset, currentOffset)}>
                {renderItem()}
            </div>
        </div>
    )
}