import './FloorPlanEditor__TagsSection.css';
import React, { useRef } from 'react';
import { CollapsableSection } from '../../../../components/CollapsableSection';


export const FloorPlanEditor__TagsSection = ({table, tags=[], onTagClick=(tagName) => {}}) => {

    const refs = useRef(tags.map((tag) => {return React.createRef()}));

    return (
        <div className="FloorPlanEditor__TagsSection">
            <CollapsableSection title="Tags" collapsable>
            {table?
                <div className="FloorPlanEditor__TagsSection__container">
                    <div className="FloorPlanEditor__TagsSection__tags">
                    {
                        tags.map((tag, i) => {
                            return (
                                <div key={i} className="FloorPlanEditor__TagsSection__tag" onClick={() => {onTagClick(tag)}}>
                                    <span className="FloorPlanEditor__TagsSection__tag-title">{tag}</span>
                                    <input disabled checked={table['tags'].includes(tag)} ref={refs.current[i]} onClick={(e) => {}} className="FloorPlanEditor__TagsSection__tag-checkbox" type="checkbox"/>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
                :""}
            </CollapsableSection>
        </div>
    )
}

