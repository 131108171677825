import './FloorPlanEditor__Map.css';
import { useCallback } from 'react';
import { useDrop } from 'react-dnd';
import { ItemTypes } from '../FloorPlanEditor__dndConstants';
import { FloorPlanEditor__Table } from '../FloorPlanEditor__Table';

export const FloorPlanEditor__Map = ({tables, setTables, addTable, setEdges, snapDistance, edges, selectedTables, onTableClick=(e, id)=>{}, onBackgroundClick=()=>{}, onDragging=(id)=>{}, onTableSizeChange=(id, width, height)=>{}, floorPlanImageUrl}) => {

    function closestDigit(x, digits) {
        let closest = digits[0];
        let diff = Math.abs(x - closest);
        
        for (let i = 1; i < digits.length; i++) {
        const currentDiff = Math.abs(x - digits[i]);
        if (currentDiff < diff) {
            closest = digits[i];
            diff = currentDiff;
        }
        }
        
        return [closest, diff];
    }

    const moveTable = useCallback(
        (id, left, top) => {
            let tmpTable = tables[id]

            let x = tables[id].left + left
            let y = tables[id].top + top


            //x
            if(edges.x.length !== 0){
                let [closest, diff] = closestDigit(x, edges.x);
                let [closestToWidth, widthDiff] = closestDigit(x + tmpTable.width, edges.x);

                if(diff < widthDiff){
                    if(diff <= snapDistance){
                        x = closest
                    } 
                } else {
                    if(widthDiff <= snapDistance){
                        x = closestToWidth - parseInt(tmpTable.width)
                    }
                }
                
            }

            //y
            if(edges.y.length !== 0){
                let [closest, diff] = closestDigit(y, edges.y);
                let [closestToHeight, heightDiff] = closestDigit(y + tmpTable.height, edges.y);

                if(diff < heightDiff){
                    if(diff <= snapDistance){
                        y = closest
                    } 
                } else {
                    if(heightDiff <= snapDistance){
                        y = closestToHeight - tmpTable.height
                    }
                }
            }

            
            tmpTable.top = y
            tmpTable.left = x
            tables[id] = tmpTable
            setTables({...tables})
        },
        [tables, edges.x, edges.y, setTables, snapDistance],
    )

    const [, drop] = useDrop(
        () => ({
            accept: [ItemTypes.TABLE, ItemTypes.TABLE_TOOLBOX],
            drop(item, monitor) {
                switch (monitor.getItemType()){
                    case ItemTypes.TABLE:
                        const delta = monitor.getDifferenceFromInitialOffset()
                        let left = Math.round(delta.x)
                        let top = Math.round(delta.y)
                        moveTable(item.id, left, top)
                        return undefined
                    case ItemTypes.TABLE_TOOLBOX:
                        const toolbox_delta = monitor.getSourceClientOffset()
                        addTable({left:toolbox_delta.x, top:toolbox_delta.y + window.scrollY})
                        return undefined
                    default:
                        return undefined;
                }
                
            },
        }),
        [moveTable],
    )

    return (
        <div className='FloorPlanEditor__Map' ref={drop} onClick={()=>{onBackgroundClick()}}>
            {floorPlanImageUrl?
                <img src={floorPlanImageUrl} alt="Floor Map" className="FloorPlanEditor__Map__image" />
            :<></>} 
            {
                Object.keys(tables).map((key)=>{
                    return(<FloorPlanEditor__Table 
                            selected={selectedTables.indexOf(key) !== -1} 
                            onDragging={(e) => {onDragging(e)}} 
                            onTableClick={(e)=>{onTableClick(e, key)}} 
                            id={key} 
                            key={key} 
                            tables={tables}
                            tableNumber={tables[key]['tableNumber']}
                            setEdges={setEdges}
                            onTableSizeChange={(id, width, height) => {onTableSizeChange(id, width, height)}}
                            {...tables[key]}></FloorPlanEditor__Table>)
                })
            }
        </div>
    )
}