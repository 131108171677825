import './FloorMapTable.css';
import { FloorMapTableTag } from '../FloorMapTableTag';
import { useRef } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';


export const FloorMapTable = (props) => {

    const containerRef = useRef();

    return (

        <div onPointerDown={(e) => {e.stopPropagation()}} onPointerUp={(e) => {props.clicked(); e.stopPropagation()}} className={`FloorMapTable ${props.obfuscated?"obfuscated":""} ${props.available?"available":""} ${props.open?"active":""}`} ref={containerRef} style={{top: `${props.y}px`, left: `${props.x}px`, width: props.open?'':`${props.width}px`, height:props.open?'':`${props.height}px`}}>
            <div className="FloorMapTable__table-number-container">
                <h1 className="FloorMapTable__table-number">{props.number}</h1>
            </div>

            {
                props.threeSixtyImage == null?<></>:
                <div className={`FloorMapTable__image_open_button ${props.open?"open":""}`} onClick={()=>{props.open360Popup(true, props.threeSixtyImage)}}>
                    <div className='FloorMapTable__image_open_icon'></div>
                </div>
            }
            
            {
                props.obfuscated?
                <p className="FloorMapTable__sub-text">Occupancy unavailable</p>:
                <p className="FloorMapTable__sub-text">{props.available?"Available":"Unavailable"} since {props.lastSeen?props.lastSeen.toLocaleTimeString("en-GB", { hour:"numeric", minute:"numeric" }):"N/A"}</p>
            }
            
            <span className="FloorMapTable__status-color-bar" />

            <ScrollContainer onMouseDown={(e)=>{console.log(e)}} className="FloorMapTable__tags-scroll-container" horizontal={true} vertical={false}>
                <div className="FloorMapTable__tags">
                    {props.tags!=={}?
                        props.tags.map((tag, index) => {
                            return (
                                <FloorMapTableTag key={index}>{tag}</FloorMapTableTag>
                            )
                        })
                    :""}
                </div>
            </ScrollContainer>
        </div>
    )
}

