import './EditableText.css';
import { useState, useEffect, useRef } from 'react';

export const EditableText = ({text="", onChange=(text) => {}}) => {
    const [currentText, setCurrentText] = useState("Floor 2");
    const inputRef = useRef();

    function handleChange(e) {
        setCurrentText(e.target.value);
        onChange(e.target.value);
    }

    useEffect(() => {
        if (currentText === "") {
            setCurrentText("Untitled");
        }
    }, [currentText])

    return (
        <div className="EditableText">
            <input ref={inputRef} placeholder="Untitled" className="EditableText__input" type="text" defaultValue={text} onChange={handleChange} size={currentText.length}/>
  
        </div>
    )
}

