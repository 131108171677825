import './FloorPlanEditor__TablesSection.css';
import { CollapsableSection } from '../../../../components/CollapsableSection';


export const FloorPlanEditor__TablesSection = ({tables=[], selectedTables=[], onTableClick=() => {}}) => {
    return (
        <div className="FloorPlanEditor__TablesSection">
            <CollapsableSection title="Tables" style={{height: "100%"}} collapsable={false}>
                <div className="FloorPlanEditor__TablesSection__container">
                    {Object.keys(tables).sort((a, b) => {return tables[a]['tableNumber'] - tables[b]['tableNumber']}).map((key, i) => {
                        return <span key={i} className={`FloorPlanEditor__TablesSection__table ${selectedTables.includes(key)?"selected":""}`} onClick={(e) => {onTableClick(e, key)}}>Table {tables[key]['tableNumber']}</span>
                    })}
                </div>
            </CollapsableSection>
        </div>
    )
}

