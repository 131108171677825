import './TableSearchItem.css';

export const TableSearchItem = ({id=0, tableNumber=0, available=false, onClick=()=>{}, floorData={}}) => {

    return (
        <div className={`TableSearchItem ${available?"available":""} ${floorData.obfuscated?"obfuscated":""}`} onClick={onClick}>
            <h1 className="TableSearchItem__title">Table {tableNumber}</h1>
            <span className="TableSearchItem__color-bar" />
            {
                floorData.obfuscated?
                <p className="TableSearchItem__availability">Occupancy unavailable</p>:
                <p className="TableSearchItem__availability">{available?"available":"unavailable"}</p>
            }
        </div>
    )
}