import './TableSearch.css';
import { TableSearchItem } from './TableSearchItem';
import { Link } from 'react-router-dom';
import { FloorSelectorButton } from '../FloorSelectorButton';
import { useState, useEffect, useRef } from 'react';

export const TableSearch = ({floorData={}, onTableClick=(id)=>{}, otherFloors=[], selectedFloorId=0, onFloorChange=(id) => {}}) => {
    const containerRef = useRef();
    const searchInputRef = useRef();

    const [opened, setOpened] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [indexesThatMatchSearch, setIndexesThatMatchSearch] = useState([]);

    useEffect(() => {
        function handleDocumentClick(e) {
            if (containerRef.current && !containerRef.current.contains(e.target)) {
                // if the click was outside containerRef
                setOpened(false)
            }
        }

        // add click listener to document
        document.addEventListener("mousedown", handleDocumentClick);

        return () => {
            // remove listener on cleanup
            document.removeEventListener("mousedown", handleDocumentClick);
        };
    }, [containerRef,]);

    useEffect(() => {
        // triggers when searchValue changes, i.e when typing in the input box
        let temp_indexes=[]
        for (let i in floorData.tables) {
            if (floorData.tables[i].tableNumber) {
                if (floorData.tables[i].tableNumber.toString() === searchValue) {
                    temp_indexes.push(parseInt(i));
                }
            }
        }
        setIndexesThatMatchSearch(temp_indexes);
    }, [searchValue,floorData.tables])


    function handleTableClick(e, id) {
        // runs internally everytime a table is clicked, then runs passed in onTableClick()
        setOpened(false);
        e.stopPropagation();
        clearSearchBox();
        onTableClick(id);
    }

    function clearSearchBox() {
        // clear input value and set state of value to ""
        searchInputRef.current.value="";
        setSearchValue("");
    }

    return (
        <div ref={containerRef} className={`TableSearch ${opened?"active":""}`} onClick={()=>{setOpened(true)}}>
            <div className="TableSearch__selected-floor">
                <FloorSelectorButton floors={otherFloors} selectedFloorId={selectedFloorId} onFloorChange={(id) => onFloorChange(id)} paddingBottom={40}/>
            </div>

            <input ref={searchInputRef} disabled={!opened} onChange={(e) => {setSearchValue(e.target.value)}} className="TableSearch__search-input" type="text" placeholder="Search" />
            
            {floorData.site_id?
                <>
                    <Link to={floorData?`/building?building=${floorData.site_id}`:""}>
                        <span className="TableSearch__back-button">{`Back to ${floorData.site_name}`}</span>
                    </Link>
                </>
            :""}
            <div className="TableSearch__table-list">
            {
                floorData.tables.map((table, index) => {
                    return (
                        !searchValue||indexesThatMatchSearch.includes(index)?
                            <TableSearchItem
                                onClick={(e)=>{handleTableClick(e, table.table_id)}}
                                key={index}
                                available={!table.table_occupied}
                                tableNumber={table.tableNumber}
                                floorData={floorData}/>
                        :""
                    )
                })
            }
            </div>
        </div>
    )
}
