import './FloorPlanEditor__ItemsSection.css';
import { CollapsableSection } from '../../../../components/CollapsableSection';
import { FloorPlanEditor__ToolboxTable } from '../../FloorPlanEditor__ToolboxTable';


export const FloorPlanEditor__ItemsSection = (props) => {

    function uuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
            return v.toString(16);
        });
    }


    return (
        <div className="FloorPlanEditor__ItemsSection">
            <CollapsableSection title="Toolbox">
                <div className="FloorPlanEditor__ItemsSection__container">
                    <div className="FloorPlanEditor__ItemsSection__floor-map-table-container">
                        <FloorPlanEditor__ToolboxTable id={uuid()} left={0} top={0}/>
                    </div>
                </div>
            </CollapsableSection>
        </div>
    )
}

