import './FloorMap.css'
import { FloorMapTable } from '../FloorMapTable'
import { ScaleButtons } from '../ScaleButtons'
import ScrollContainer from 'react-indiana-drag-scroll';
import { useState, useRef, useImperativeHandle, forwardRef } from 'react';
import { Loader } from '../Loader';

export const FloorMap = forwardRef(({floorData={}, open360Popup=()=>{}}, ref) => {
    const [openTableId, setOpenTableId] = useState(-1);
    const [scale, setScale] = useState(1);

    const scrollContainerRef = useRef();

    useImperativeHandle(ref, () => ({
        openTable(id) {
            openTable(id)
        },
        focusOnTable(id) {
            focusOnTable(id)
        }
    }));

    function closeAllTables() {
      setOpenTableId(-1);
    }

    function openTable(id) {
      setOpenTableId(id);
    }

    function focusOnTable(id) {
      openTable(id)
      let table = floorData.tables.find(table => table.table_id === id);
      if (!table) {
        console.error(`Table ${id} does not exist.`);
      }
      scrollContainerRef.current.scrollTo({
        left: table.map_x - (scrollContainerRef.current.clientWidth / 2) + 125,
        top: table.map_y - (scrollContainerRef.current.clientHeight / 2) + 70,
        behavior: 'smooth'
      })
    }

    function zoomMapIn() {
      setScale(scale + 0.1);
    }

    function zoomMapOut() {
      setScale(scale - 0.1);
    }

    return (
        <div className="FloorMap" onPointerDown={()=>{closeAllTables()}}>
            <ScaleButtons onUpClick={() => {zoomMapIn()}} onDownClick={() => {zoomMapOut()}}/>
            {
              floorData.floor_map_image_s3_object?
                <>
                  <ScrollContainer innerRef={scrollContainerRef} horizontal={true} vertical={true} style={{maxHeight:"100%"}} ignoreElements=".FloorMapTable__tags">
                    <div className="FloorMap__draggable_container" style={{transform: `scale(${scale})`}}>
                      <img alt="Floor Map" className="FloorMap__floor-plan-image" src={`//occusenseimageuploads.s3.eu-west-2.amazonaws.com/floorMapImages/${floorData.floor_map_image_s3_object}`}/>
                      {floorData.tables.map((table, index) => {
                          return (
                            <FloorMapTable
                              open360Popup={(v, i)=>{open360Popup(v,i)}}
                              key={index}
                              id={table.table_id}
                              number={table.tableNumber}
                              width={table.map_width}
                              height={table.map_height}
                              x={table.map_x}
                              y={table.map_y}
                              open={table.table_id === openTableId}
                              available={!table.table_occupied}
                              tags={table.tags}
                              lastSeen={new Date(table.last_seen * 1000)}
                              clicked={() => {focusOnTable(table.table_id)}}
                              obfuscated={floorData.obfuscated}
                              threeSixtyImage={table["360ImageS3Object"]}
                            />
                          )
                        })
                      }
                    </div>
                  </ScrollContainer>
                </>
              :
              <div style={{height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <Loader type="light" className=""/>
              </div>
          }
        </div>
    )
    
})

