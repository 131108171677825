import './Loader.css'

export const Loader = ({type="glow", className=""}) => {
    switch (type){
        case "spin":
            return (
                <span className={`spin-loader ${className}`}></span>
            )

        case "spin-small":
            return (
                <span className={`spin-loader-small ${className}`}></span>
            )

        case "light":
            return (
                <div className={`loader light ${className}`}>
                    <div className="loader-ring-1 loader-ring"></div>
                    <div className='loader-ring'/>
                </div>
            )

        default:
            return (
                <div className={`loader ${className}`}>
                    <div className="loader-ring-1 loader-ring"></div>
                    <div className='loader-ring'/>
                </div>
            )
    }    
}
