import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { BuildingSummary } from './routes/BuildingSummary'
import { FloorTest } from './routes/FloorTest';
import { Buildings } from './routes/Buildings';

import { FloorSummary } from './routes/FloorSummary'
import { FloorPlanEditor } from './routes/FloorPlanEditor'
import 'semantic-ui-css/semantic.min.css';
import { DeviceMetrics } from './routes/DeviceMetrics';
import { MarketingPage } from './routes/Marketing';
import { Login } from './routes/Login';
import { ErrorPageNotFound } from './routes/404';
import { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

//import authPrvider
//import azureAD

export default function AppRoutes() {
  // detects if viewport width is less than 768px (i.e. mobile) and adds the class 'mobile' to the html element.
  if (window.matchMedia("(min-width: 850px)").matches) {document.getElementsByTagName('html')[0].classList.remove("mobile")} else {document.getElementsByTagName('html')[0].classList.add("mobile")};
  
  // detects if user is in dark mode, and adds the class 'dark' to the html element.
  if (!window.matchMedia("(prefers-color-scheme: dark)").matches) {document.getElementsByTagName('html')[0].classList.remove("dark")} else {document.getElementsByTagName('html')[0].classList.add("dark")};
  useEffect(() => {
    window
    .matchMedia("(min-width: 850px)")
    .addEventListener('change', (e) => {if (e.matches) {document.getElementsByTagName('html')[0].classList.remove("mobile")} else {document.getElementsByTagName('html')[0].classList.add("mobile")} } );
  
    window
    .matchMedia("(prefers-color-scheme: dark)")
    .addEventListener('change', (e) => {if (!e.matches) {document.getElementsByTagName('html')[0].classList.remove("dark")} else {document.getElementsByTagName('html')[0].classList.add("dark")} } );
    

  }, []);


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MarketingPage/>}/>
        <Route path="building" element={<BuildingSummary/>}/>
        <Route path="floor/:floorId" element={<FloorSummary/>}/>
        <Route path="floor-editor/:floorId" element={<FloorPlanEditor/>}/>
        <Route path="metrics" element={<DeviceMetrics/>}/>
        <Route path="404" element={<ErrorPageNotFound/>}/>
        <Route path='*' element={<ErrorPageNotFound/>}/>
        <Route path="login" element={<Login/>}/>
        <Route path="dragtest" element={<FloorTest/>}/>
        <Route path="home" element={<Buildings/>}/>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
    <link rel="manifest" href="/site.webmanifest"/>
    <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#929292"/>
    <meta name="msapplication-TileColor" content="#2b5797"/>
    <meta name="theme-color" content="#ffffff"></meta>
    <DndProvider backend={HTML5Backend}>
      <AppRoutes />
    </DndProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
