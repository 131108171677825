import './FloorPlanEditor__360ImageSection.css';
import { CollapsableSection } from '../../../../components/CollapsableSection';


export const FloorPlanEditor__360ImageSection = ({table}) => {

    return (
        <div className="FloorPlanEditor__360ImageSection">
            <CollapsableSection title="360º Image" collapsable>
                {table?
                    <div className="FloorPlanEditor__360ImageSection__container">
                        <span className="FloorPlanEditor__360ImageSection__image"></span>
                        <button className="FloorPlanEditor__360ImageSection__select-image-button">Select Image</button>
                    </div>
                :""}
            </CollapsableSection>
        </div>
    )
}

