import React, { useState } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router';

// Tutorial used: https://blog.logrocket.com/guide-adding-google-login-react-app/
// Official Google documentation: https://developers.google.com/identity/protocols/oauth2

function LoginComponent (props) {
    const [ profile, setProfile ] = useState(null);
    const [userExists, setUserExists] = useState(true);
    const [userHasSite, setUserHasSite] = useState(false);
    const navigate = useNavigate(); // eslint-disable-line

    const {search}  = useLocation();
    const parameters = new URLSearchParams(search);

    // URL must contain an organisation parameter of an existing organisation
    const orgId = parameters.get('organisation');

    // function to handle Google login request using Google's API.
    const login = useGoogleLogin({
        // On succesful use of Google's login service
        onSuccess: (codeResponse) => {
            // Make an axios request to return the user's information using the returned access token in codeResponse
            axios
            .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
                headers: {
                    Authorization: `Bearer ${codeResponse.access_token}`,
                    Accept: 'application/json'
                }
            })
            // Then return the response from the GET request.
            .then((res) => {
                // Set the state variable for user profile using the returned data.
                setProfile(res.data);
                // Using the email that the user used to succesfully login to Google services, make an API call to
                // the DB to check the email is also registered on OccuSense.
                fetch(`${process.env.REACT_APP_API_URL}/login/`+String(res.data.email)+`?organisation=`+orgId)
                    .then(response => 
                        // Take the response and convert it to JSON.
                        response.json()
                    )    
                    .then(response => {
                        
                        // If the response fails to return data about a user, they do not exist.
                        if(response.userNotFound){
                            // Set the state variable for user existence to false.                            
                            setUserExists(false)
                        }else{
                            if(response.organisation !== "invalid"){
                                // Organisations only send out invites to emails and no other data is added
                                // to the database. If a user's name is not initialised but their email exists,
                                // it is their first time logging in.
                                let usersFirstLogin = "false";
                                if(response.name === ""){
                                    usersFirstLogin = "true";
                                }
                                // if the user is in the Building or Organisation Admin permission group...
                                if(response.permission === 3 || response.permission === 4){
                                    // if the user has sites registered to their access
                                    if(response.sites.length > 0){
                                        // change the state variable tracking whether the user has access to a building
                                        // site page
                                        setUserHasSite(true)
                                        
                                        // Then navigate to the first building (by default) that they have access to
                                        navigate('/building?building='+String(response.sites[0]),{
                                            // Send data with the redirect so that the building page knows which user
                                            // is logged in. 
                                            state:{
                                                // Return the user ID
                                                id:response.id,
                                                // Return all sites the user can view
                                                sites: response.sites,
                                                // Return which organisation the user belongs to
                                                organisation: response.organisation,
                                                // The user has never logged on before and so must onboard
                                                firstLogin:usersFirstLogin,
                                                // Return all permissions associated with the user
                                                permission: response.permission
                                            }
                                        })
                                    }else{
                                        // If there are no sites registered then the user cannot be redirected to a site
                                        setUserHasSite(false)
                                    }
                                }
                                // If the user has any System Admin permission group access...
                                else if(response.permission === 2 || response.permission === 1){
                                    // Redirect the user to the admin dashbaord (OccuSense)
                                    navigate('/adminDashboard?organisation='+String(response.organisation),{
                                        // Pass on information about the user
                                        state:{
                                            // User ID
                                            id:response.id,
                                            // User organisation (is likely to be OccuSense in this case)
                                            organisation: response.organisation,
                                            // Initialise user's first login attempt
                                            firstLogin:usersFirstLogin,
                                            // Pass on the user's specific permission group
                                            permission: response.permission
                                        }
                                    })
                                }
                                // if the user only has to access to specific buildings but is not an Admin
                                else if(response.permission === 5){
                                    // if the user has sites regsitered to them...
                                    if(response.sites.length > 0){
                                        // initialise the variable for their having sites
                                        setUserHasSite(true)
                                        // redirect the user to the first site in their available sites
                                        navigate('/building?building='+String(response.sites[0]),{
                                            // pass user information with the redirect
                                            state:{
                                                // user ID
                                                id:response.id,
                                                // all sites the user has access to
                                                sites: response.sites,
                                                // user's first time loggin in
                                                firstLogin:usersFirstLogin,
                                                // user permission group
                                                permission: response.permission
                                            }
                                        })
                                    }else{
                                        // if no sites are found, set the user sites state boolean to false
                                        setUserHasSite(false)
                                    }
                                }
                                // if no recognised permission has been found...
                                else{
                                    // Redirect the user to an error page
                                    navigate('/404', {state:{error:"No valid permission found for access."}})
                                }
                            }else{
                                navigate('/404', {state:{error:"You do not have access to this organisation."}})
                            }
                            
                            
                        }
                        
                    })
            })
            // log any errors in the request
            .catch((err) => console.log(err)); 
        },
        // log any errors logging in
        onError: (error) => console.log('Login Failed:', error)
    });

    // function to log a user out of their google accoutn locally
    const logOut = () => {
        // use google's service to log a user out
        googleLogout();
        // remove all local instances of the user profile from the page
        setProfile(null);
    };

    return (
        <div>
            {profile ? (
                <div>
                    {!userExists ? (
                        <div>
                            <h2>You do not have an account. Plase contact your organisation for an invite to OccuSense.</h2>      
                            <button className='loginButton shadow' onClick={logOut}>Go Back</button>
                        </div>
                    ) : <div>
                            {!userHasSite ? (
                                <div>
                                    <h2>You have not been registered to any buildings. Please contact your organisation for an invite.</h2>
                                    <button className='loginButton shadow' onClick={logOut}>Log Out</button>
                                </div>
                            ):<></>}
                        </div>}
                </div>
            ) :
            (
                <div>
                    <button className='loginButton shadow' onClick={() => login()}>Sign in with Google</button>
                </div>
            )}
        </div>
    );
}

export default LoginComponent;
