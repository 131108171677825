import './marketing.css'


export const HeroSection = () => {
	return (
		<div className="m_hero_container">
			<div className='m_spacer' style={{height:"115px"}}></div>
			<div className='m_hero_text_section'>
				<p className='m_hero_text'>Get <span>real time</span> insights into your space with our <span>all in one</span> sensor network...</p>
				<a href="#contact" className='m_a_tag'>
					<button className='m_hero_cta'>Get Started Now</button>
				</a>
			</div>
			<div className='m_hero_bottom_section'>
				<div className='m_hero_page_preview m_preview_1'></div>
				<div className='m_hero_page_preview m_preview_2'></div>
			</div>
		</div>
	)
}
