import './FloorPlanEditor__ToolboxTable.css';
import { useEffect } from 'react';
import { ItemTypes } from '../FloorPlanEditor__dndConstants';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { FloorMapTable } from '../../../components/FloorMapTable';

export const FloorPlanEditor__ToolboxTable = ({id, left, top}) => {
    /* eslint-disable no-unused-vars */
    const [{isDragging}, drag, preview] = useDrag(() => ({
        type: ItemTypes.TABLE_TOOLBOX,
        item: { id, left, top },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    }))
    /* eslint-enable no-unused-vars */

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true })
    }, [preview])

    return (
        <div className='FloorPlanEditor__ToolboxTable__table-preview' key={id} id={id} style={{left:left, top:top}}>
            <div className='FloorPlanEditor__ToolboxTable__table-container' ref={drag}>
                <FloorMapTable
                      id={1}
                      number={null}
                      x={0}
                      y={0}
                      open={false}
                      available={true}
                      tags={[]}
                      lastSeen={new Date()}
                      clicked={() => {}}
                    />
            </div>
        </div>
    )
}