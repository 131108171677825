import { useEffect, useRef } from 'react';
import './BuildingSummaryMobileHeader.css';
import { useNavigate } from 'react-router-dom';

export const BuildingSummaryMobileHeader = ({backgroundImageUrl="", backTo="/", buildingName=""}) => {
	const imageRef = useRef();
	const imageTitleRef = useRef();
	const headerBackgroundRef = useRef();
	const headerTitleRef = useRef();
	const headerBackButtonRef = useRef();

	const navigate = useNavigate();

	function handleBackButtonClick() {
		// navigate to the url passed in as prop.
		navigate(backTo)
	}

	useEffect(() => {
		let animateId

		function animateFunction() {
			// runs each frame
			if (headerBackgroundRef.current) {	
				// if scrolling downwards (i.e. not bouncing back on iOS)
		    	if (window.pageYOffset > 0) {
		    		// transition between static header and fixed header
			    	imageRef.current.style.transform = `translateY(-${(window.scrollY/4)}px)`
		    		imageRef.current.style.opacity = 1 - ((window.scrollY)/250)
		    		imageTitleRef.current.style.opacity = 1 - ((window.scrollY)/150)
		    		headerTitleRef.current.style.opacity = ((window.scrollY-50)/150)
		    		headerBackgroundRef.current.style.opacity = ((window.scrollY-50)/150)
		    		headerBackButtonRef.current.style.opacity = 1-((window.scrollY-50)/150)

			    } else {
			    	// restore to default values
			    	imageRef.current.style.transform = ``;
			    	imageRef.current.style.opacity = 1;
			    	headerTitleRef.current.style.opacity = 0;
			    	headerBackgroundRef.current.style.opacity = 0;
			    	imageTitleRef.current.style.opacity = 1;
			    	headerBackButtonRef.current.style.opacity = 1;
		    	}
			    animateId = window.requestAnimationFrame(animateFunction);
			}
		}

		// request next animation frame, and store id
		animateId = window.requestAnimationFrame(animateFunction);

		return () => {
			// on clear up, cancel next animation frame
			window.cancelAnimationFrame(animateId)
		}

	})

	return (
		<>
			<div className="BuildingSummaryMobileHeader__image-container">
				<span ref={imageRef} className="BuildingSummaryMobileHeader__image" style={{backgroundImage: `url('${backgroundImageUrl}')`}}/>
			</div>
			<div className="BuildingSummaryMobileHeader__static-section">
				<h1 ref={imageTitleRef} className="BuildingSummaryMobileHeader__title">{buildingName}</h1>
			</div>

			<div className="BuildingSummaryMobileHeader__header">
				<span ref={headerBackgroundRef} className="BuildingSummaryMobileHeader__header-background" />

					<span ref={headerBackButtonRef} className="BuildingSummaryMobileHeader__back-button top" onClick={handleBackButtonClick}>Back</span>
					<span className="BuildingSummaryMobileHeader__back-button">Back</span>


				<h1 ref={headerTitleRef} className="BuildingSummaryMobileHeader__header-title">{buildingName}</h1>
			</div>
		</>
	)
}

