import './FloorPlanEditor.css';
import { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from "react-router-dom";
import { FloorPlanEditor__Map } from './FloorPlanEditor__Map';
import { FloorPlanEditor__CustomDragLayer } from './FloorPlanEditor__CustomDragLayer';
import { EditableText } from '../../components/EditableText';
import { FloorPlanEditor__TablesSection } from './Sections/FloorPlanEditor__TablesSection';
import { FloorPlanEditor__BackgroundImageSection } from './Sections/FloorPlanEditor__BackgroundImageSection';
import { FloorPlanEditor__ItemsSection } from './Sections/FloorPlanEditor__ItemsSection';
import { FloorPlanEditor__TableInformationSection } from './Sections/FloorPlanEditor__TableInformationSection';
import { FloorPlanEditor__SizeAndPositionSection } from './Sections/FloorPlanEditor__SizeAndPositionSection';
import { FloorPlanEditor__TagsSection } from './Sections/FloorPlanEditor__TagsSection';
//import { FloorPlanEditor__BorderSection } from './Sections/FloorPlanEditor__BorderSection';
//import { FloorPlanEditor__ColorSection } from './Sections/FloorPlanEditor__ColorSection';
import { FloorPlanEditor__360ImageSection } from './Sections/FloorPlanEditor__360ImageSection';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Link } from 'react-router-dom';

function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
        return v.toString(16);
    });
}

export const FloorPlanEditor = () => {
    const scrollContainerRef = useRef();

    let { floorId } = useParams();
    const [floorName, setFloorName] = useState("");
    const [deletedTableIds, setDeletedTableIds] = useState([]);
    const [floorPlanImageUrl, setFloorPlanImageUrl] = useState("");

   
    const tags = ["Disabled Access", "Printer", "Computer"];

 /*john*/

    const [tables, setTables] = useState({})
    const [selectedTables, setSelectedTables] = useState([])
    const [edges, setEdges] = useState({x:[], y:[]});

    const snapDistance = 10;

    const deleteSelectedTables = useCallback(() => {
        for (let tableId of Object.keys(tables)){
            if(selectedTables.indexOf(tableId) !== -1){
                deletedTableIds.push(tableId);
                setDeletedTableIds([...deletedTableIds]);
                delete tables[tableId]
            }
        }
        setTables({...tables})
    }, [tables, selectedTables, deletedTableIds]);

    useEffect(()=>{
        document.onkeydown = (e) => {
            switch(e.key){
                case "Backspace":
                    deleteSelectedTables();
                    break;
                case "Delete":
                    deleteSelectedTables();
                    break;
                default:
                    break;
            }
        };
    }, [tables, selectedTables, deleteSelectedTables])

    useEffect(() => {
        // fetch initial data
        fetch(`${process.env.REACT_APP_API_URL}/floor/${floorId}`)
            .catch((err) => {
            })
            .then(res => res.json())
            .then(data => {
                setFloorName(data.floor_name);
                setFloorPlanImageUrl(`//occusenseimageuploads.s3.eu-west-2.amazonaws.com/floorMapImages/${data.floor_map_image_s3_object}`);
                for (let table of data.tables) {
                    tables[table.public_id] = {
                        top: table.map_y,
                        left: table.map_x,
                        width: table.map_width,
                        height: table.map_height,
                        tableNumber: table.tableNumber,
                        tags: table.tags
                    };
                    setTables({...tables});
                }
            })
    // eslint-disable-next-line
    }, [])

    

    function sendData() {
        fetch(`${process.env.REACT_APP_API_URL}/floor/${floorId}`, {
            method: "POST",
            headers: {'Content-Type': 'application/json'}, 
            body: JSON.stringify({
                tables: tables,
                floorName: floorName,
                deletedTableIds: deletedTableIds
            })
        }).then(res => {
        });
    }

    function addTable({top=0, left=0}){

        var viewportOffset = document.getElementsByClassName("FloorPlanEditor__Map")[0].getBoundingClientRect();

        tables[uuid()] = {top:top - viewportOffset.top, left:left - viewportOffset.left, width: 45, height:45, tableNumber: null, tags:[]}
        setTables({...tables})
    }

    const selectTable = ({e={}, id}) => {
        if (e.shiftKey) {
            if (selectedTables.indexOf(id) === -1) {
                selectedTables.push(id);
                setSelectedTables([...selectedTables]);
            } else {
                let toRemoveIndex = selectedTables.indexOf(id);
                selectedTables.splice(toRemoveIndex, 1); 
                setSelectedTables([...selectedTables]);
            }
        } else {
            setSelectedTables([id]);
        }

    }

    function updateTableSize(id, width, height){
        tables[id].height = height
        tables[id].width = width
        setTables({...tables})
    }

    function updateTablePosition(id, x, y){ 
        tables[id].left = x;
        tables[id].top = y;
        setTables({...tables});
    }

    function updateTableNumber(id, tableNumber) {
        tables[id].tableNumber = tableNumber;
        setTables({...tables});
    }

    function deselectAllTables() {
        setSelectedTables([]);
    }

    function handleTagClick(tableId, tagName) {
        if (tables[tableId]['tags'].includes(tagName)) {
            tables[tableId]['tags'].splice(tables[tableId]['tags'].indexOf(tagName), 1);
        } else {
            tables[tableId]['tags'].push(tagName);
        }
        setTables({...tables});
    }

/* endjohn */


    return (
        <>
            <div className="FloorPlanEditor">

                <div className="FloorPlanEditor__toolbar">
                    <EditableText text={floorName} onChange={(text) => {setFloorName(text)}}/>
                    <span className="FloorPlanEditor__save-button" onClick={sendData}>Save</span>
                    {/* UP TO DATE?, preview*/}
                </div>

                <div className="FloorPlanEditor__pane left">
                    <FloorPlanEditor__TablesSection tables={tables} selectedTables={selectedTables} onTableClick={(e, id) => {selectTable({e:e, id:id})}}/>
                    <FloorPlanEditor__BackgroundImageSection />
                    <FloorPlanEditor__ItemsSection />
                </div>

                <div className="FloorPlanEditor__pane right">
                    <FloorPlanEditor__TableInformationSection
                        table={tables[selectedTables[0]]}
                        onNumberChange={(newValue) => {updateTableNumber(selectedTables[0], newValue)}}
                    />

                    <FloorPlanEditor__SizeAndPositionSection
                        table={tables[selectedTables[0]]}
                        onXChange={(newValue) => updateTablePosition(selectedTables[0], parseInt(newValue), tables[selectedTables[0]]['top'])}
                        onYChange={(newValue) => updateTablePosition(selectedTables[0], tables[selectedTables[0]]['left'], parseInt(newValue))}
                        onWidthChange={(newValue) => updateTableSize(selectedTables[0], parseInt(newValue), tables[selectedTables[0]]['height'])}
                        onHeightChange={(newValue) => updateTableSize(selectedTables[0], tables[selectedTables[0]]['width'], parseInt(newValue))}
                    />

                    <FloorPlanEditor__TagsSection table={tables[selectedTables[0]]} tags={tags} onTagClick={(tagName) => handleTagClick(selectedTables[0], tagName)}/>
                    {/*
                    <FloorPlanEditor__BorderSection />
                    <FloorPlanEditor__ColorSection />
                    */}

                    <FloorPlanEditor__360ImageSection table={tables[selectedTables[0]]} />
                </div>

                <div className="FloorPlanEditor__center-container">
                    <ScrollContainer ref={scrollContainerRef} horizontal={true} vertical={true} style={{height:"100%", width:"100%"}} ignoreElements=".FloorMapTable, .react-resizable">
                        <FloorPlanEditor__Map
                            onDragging={(id) => {selectTable({id})}} 
                            onBackgroundClick={() => {deselectAllTables()}} 
                            onTableClick={(e, id) => {selectTable({e, id})}} 
                            selectedTables={selectedTables} 
                            tables={tables} 
                            setTables={setTables} 
                            addTable={addTable}
                            onTableSizeChange={(id, width, height)=>{updateTableSize(id, width, height)}}
                            setEdges={setEdges}
                            edges={edges}
                            snapDistance={snapDistance}
                            floorPlanImageUrl={floorPlanImageUrl}
                        />
                    </ScrollContainer>
                    
                    <FloorPlanEditor__CustomDragLayer 
                        edges={edges}
                        snapDistance={snapDistance}
                    />
                </div>

            </div>

            <div className='FloorPlanEditor__mobile'>
                <p>This page is not available on smaller devices, please use a desktop</p>
                <Link to={-1}>Back</Link>
            </div>
        </>
    )
}
