import { FloorMap } from '../../components/FloorMap';
import { RefreshButton } from '../../components/RefreshButton';
import { TableSearch } from '../../components/TableSearch';
import { FloorSummaryHeader } from '../../components/FloorSummaryHeader';
import { Popup } from '../../components/Popup';
import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import useAppName from '../../hooks/useAppName';
/*import { Pannellum } from "pannellum-react";*/
import './FloorSummary.css';

export const FloorSummary = (props, ref) => {
    let { floorId } = useParams();

    const navigate = useNavigate();

    const [floorData, setFloorData] = useState({tables:[]});
    const [otherFloors, setOtherFloors] = useState([]);
    const [lastUpdated, setLastUpdated] = useState(0);
    const [updating, setUpdating] = useState(true);
    const [disconnected, setDisconnected] = useState(false);
    const appName = useAppName()

    const [popupOpen, setPopupOpen] = useState(false);
    /*const [loadedImage, setLoadedImage] = useState("");*/

    const testRef = useRef();

    function fetchInitialData() {
        setFloorData({tables:[]})
        setUpdating(true);
        fetch(`${process.env.REACT_APP_API_URL}/floor/${floorId}`)
            .catch((err) => {
                setUpdating(false);
                setDisconnected(true);
            })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                setFloorData(data);
                document.title = appName + " | " + data.site_name
                setOtherFloors(data.other_floors);
                setLastUpdated(new Date());
                setUpdating(false);
                setDisconnected(false);
                console.log(data)
            })
    }

    function refreshData() {
        setUpdating(true);
        setDisconnected(false);

        fetch(`${process.env.REACT_APP_API_URL}/floor/${floorId}/refresh`)
            .catch((err) => {
                setUpdating(false);
                setDisconnected(true);
            })
            .then(res => res.json())
            .then(data => {
                floorData.obfuscated = data.obfuscated
                for (let [index, table] of data.tables.entries()) {
                    floorData.tables[index]["last_seen"] = table["last_seen"];
                    floorData.tables[index]["table_occupied"] = table["table_occupied"];
                }
                setLastUpdated(new Date());
                setUpdating(false);
                setDisconnected(false);
            })
    }

    function changeFloor(id) {
        navigate(`/floor/${id}`)
    }

    useEffect(()=>{
        // When floorId changes in URL, refetch the initial data.
        fetchInitialData();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [floorId, useAppName])

    return (
        <div className="FloorSummary">

            <Popup
                open={popupOpen}
                setOpen={setPopupOpen}
            >
                {/*<Pannellum
                    width="100%"
                    height="500px"
                    image={"https://occusenseimageuploads.s3.eu-west-2.amazonaws.com/360Images/"+loadedImage}
                    pitch={10}
                    yaw={180}
                    hfov={110}
                    autoLoad
                    onLoad={() => {
                        console.log("panorama loaded");
                    }}
                ></Pannellum>*/}
            </Popup>

            <RefreshButton onClick={()=>{refreshData()}} updating={updating} disconnected={disconnected} lastUpdated={lastUpdated}/>
            <FloorSummaryHeader onClick={()=>{refreshData()}} updating={updating} disconnected={disconnected} floorData={floorData} otherFloors={otherFloors} selectedFloorId={floorId} onFloorChange={(id)=>{changeFloor(id)}}/>

            <div className="FloorSummary__content">
                <FloorMap ref={testRef} floorData={floorData} open360Popup={(v,i) => {setPopupOpen(v);/*setLoadedImage(i)*/}}/>
            </div>

            <TableSearch floorData={floorData} onTableClick={(id)=>{testRef.current.focusOnTable(id)}} otherFloors={otherFloors} selectedFloorId={floorId} onFloorChange={(id)=>{changeFloor(id)}}/>

        </div>
    )
}
