import { ReactComponent as Instagram } from './instagram.svg'
import { ReactComponent as Twitter } from './twitter.svg'
import { ReactComponent as LogoLight } from './logolight.svg'
import './marketing.css'

export const Footer = () => {
    return (
        <div className='m_footer'>
            <div className='m_row'>
                <LogoLight/>
                <p className='m_brand_name' style={{color:"white", marginLeft:"14px"}}>occusense</p>
            </div>
            <div className='m_col'>
                <div className='m_row' style={{marginBottom:"10px"}}>
                    <Instagram style={{marginRight:"10px"}}/>
                    <p className='m_footer_text'>@occusense</p>
                </div>
                <div className='m_row'>
                    <Twitter style={{marginRight:"10px"}}/>
                    <p className='m_footer_text'>@occusense</p>
                </div>
            </div>
            <p className='m_footer_text'>2024 © OccuSense LTD. All rights reserved.</p>
        </div>
    )
}
