import {useRef, useEffect, useState} from 'react';
import { ReactComponent as TopRight } from './topright.svg'
import { ReactComponent as TopLeft } from './topleft.svg'
import { ReactComponent as BottomRight } from './bottomright.svg'
import { ReactComponent as BottomLeft } from './bottomleft.svg'

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
  export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize, true);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
  }

export const SensorExpandingSection = ({scrollPosition}) => {
    const canvasRef = useRef();
    const [loadedImgs, setLoadedImgs] = useState({})
    const { height } = useWindowDimensions();


    //Navbar height: 106px
    // 800x1500
    //800x = 1500     1500/800 = x    x = 1.875

    useEffect(() => {
        const ctx = canvasRef.current.getContext('2d');
        let frame = Math.floor((scrollPosition - 1285) / 29)
        if (frame <= 1){
            frame = 1
        }
        if (frame >= 50){
            frame = 50
        }
        if(loadedImgs[frame] !== undefined){
            ctx.drawImage(loadedImgs[frame],0,0, (height - 142) * 1.875,(height - 142));
        }
    }, [scrollPosition, height, loadedImgs])

    useEffect(() => {
        for (let i of [...Array(50).keys()]){
            i ++;
            loadedImgs[i] = new Image();
            loadedImgs[i].src = `${window.location.origin}/frames/${i}.jpg`
            setLoadedImgs(loadedImgs)
        }
    }, [loadedImgs])

    return (
        <div className="m_sensor_expanding_container">
            <p className="m_sensor_title">The OccuSensor</p>
            <div className="m_sensor" style={{top: "156px"}}>
                <canvas height={height - 142} width={(height - 142) * 1.875} ref={canvasRef}></canvas>

                <p className={`one ${scrollPosition >= 2300 ? "show" : ""}`}>Custom built, damage resistant cases</p>
                <TopRight className={`m_tr ${scrollPosition >= 2300 ? "show" : ""}`}/>

                <p className={`two ${scrollPosition >= 2500 ? "show" : ""}`}>Tamper alert system</p>
                <TopLeft className={`m_tl ${scrollPosition >= 2500 ? "show" : ""}`}/>

                <p className={`three ${scrollPosition >= 2700 ? "show" : ""}`}>Low consumption electronics for reduced maintenance</p>
                <BottomRight className={`m_br ${scrollPosition >= 2700 ? "show" : ""}`}/>

                <p className={`four ${scrollPosition >= 2900 ? "show" : ""}`}>Hidden fixings to stop unwanted interferance</p>
                <BottomLeft className={`m_bl ${scrollPosition >= 2900 ? "show" : ""}`}/>
            </div>
        </div>
    )
}
