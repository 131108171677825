import './FloorSummaryHeader.css';
import { Link } from 'react-router-dom';
import { FloorSelectorButton } from '../FloorSelectorButton';

export const FloorSummaryHeader = ({floorData={}, onClick=()=>{}, updating=false, disconnected=true, otherFloors=[], selectedFloorId=0, onFloorChange=(id) => {}}) => {
	return (
		<div className="FloorSummaryHeader">	
			{floorData.site_name?<Link className="FloorSummaryHeader__back-button" to={`/building?building=${floorData.site_id}`}>{floorData.site_name}</Link>:""}
            <FloorSelectorButton floors={otherFloors} selectedFloorId={selectedFloorId} onFloorChange={(id) => onFloorChange(id)}/>
            <span className={`FloorSummaryHeader__refresh ${updating?"active":""} ${disconnected?"disconnected":""}`} onClick={onClick} />
		</div>
	)
}

