import './RefreshButton.css';
import { Loader } from '../Loader';


export const RefreshButton = ({onClick=()=>{}, updating=false, disconnected=true, lastUpdated=0}) => {

    return (
        <div className={`RefreshButton ${updating?"updating":""} ${disconnected?"disconnected":""}`} onClick={onClick}>
            <div className="RefreshButton__container">
                <p className="RefreshButton__last-updated-text">
                    Last updated: {lastUpdated?lastUpdated.toLocaleTimeString("en-GB", { hour:"numeric", minute:"numeric" }):"never"}
                </p>
                <p className="RefreshButton__refresh-text">
                    Refresh?
                </p>

                <p className="RefreshButton__disconnected-text">
                    Not Connected
                </p>
                <Loader type="spin-small" className="RefreshButton__loader"/>
            </div>
        </div>
    )

}

