import './404.css';
import '../../fonts.css';
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
export const ErrorPageNotFound = () => {

    const [errorPageText, setErrorPageText] = useState(null)
    const location = useLocation();

    // initialisng error message
    useEffect(() =>{
        if(location.state){
            setErrorPageText(location.state.error)
        }else{
            setErrorPageText("This page does not exist!")
        }
    },[location])

    return(
        <div className='page'>
            <p className='wrongPageText'>404 - Error!</p>
            <img className='otis' src="https://occusenseimageuploads.s3.eu-west-2.amazonaws.com/occusenseImgs/sadOtis.jpg" alt="OccuSense Otis invalid page icon"></img>
            <p className='errorText'>{errorPageText}</p>
            {errorPageText === "This page does not exist!" ? (
                <div>
                    <p className='errorText'>Double check the address or</p><br/>
                    <Link to="/" className='homeText2'>go to the homepage.</Link>
                </div>
            ):<div>
                <Link to="/" className='homeText'>Go to Homepage</Link>
            </div>
            }
        </div>        
    )
}