import "./Buildings.css"
import {useLocation, useNavigate} from 'react-router-dom';
import { useState,useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import SlidingPane from "react-sliding-pane";
import { Checkbox } from 'semantic-ui-react';
import "react-sliding-pane/dist/react-sliding-pane.css";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";



export const Buildings = () => {
    const { search } = useLocation();
    const parameters = new URLSearchParams(search);
    const orgId = parameters.get('organisation');
    const [orgInfo, setOrgInfo] = useState([])
    const [buildings, setBuildings] = useState([])
    const [filteredBuildings, setFilteredBuildings] = useState([])
    const [searchedBuildings,setSearchedBuildings] = useState([])
    const [displayedBuildings, setDisplayedBuildings] = useState([])
    const [hoveredBuilding, setHoveredBuilding] = useState("")
    const [scrollAmount, setScrollAmount] = useState(0)
    const [searchQuery, setSearchQuery] = useState("")
    const [paneOpened,openPane] = useState();
    const [filters, setFilters] = useState({});
    var shownBuilding;
    const navigate = useNavigate();
    

    const google = window.google;
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyC_VN6sTlfPWf3K_cHiI7Jx6Rgm0x713q4",
      });
    const [center,setCenter] = useState();


    // eslint-disable-next-line new-parens
    var directionsService = new google.maps.DirectionsService;

    let buildingSection;
    let dist;

    /* eslint-disable */

    useEffect(() => {
        async function makeFunctionCall(){   
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                  (position) => {
                    const pos = {
                      lat: position.coords.latitude,
                      lng: position.coords.longitude,
                    };
                    console.log(pos,"pos")
                    dist = pos;
                    setCenter(pos)
                    
                  },
                  () => {
                    console.log("idk2");
                  }
                );
            } 
            fetch(`${process.env.REACT_APP_API_URL}/orgInfo/${orgId}`) //getting list of all buildings in same org for dropdown
                .then(res => res.json())
                .then(data => {
                    console.log("tings",data[1])
                    setOrgInfo({
                        "orgName":data[0][0],
                        "orgImg":data[0][1]
                    })
                    console.log(dist, dist==null)
                    if (center != null){
                        for (const i in data[1]){
                            if (data[1][i][5] != null){
                                data[1][i][5] = JSON.parse(data[1][i][5].replace(/lat/g, '"lat"').replace(/lng/g, '"lng"'));
                            } else {
                                console.log("hnnnng")
                            }
                            let distPromise = calculateAndDisplayRoute(directionsService,center,data[1][i][1],data[1][i][4])
                            distPromise.then(
                                function(value) {
                                    data[1][i].push(value)
                                    setBuildings([...data[1]])
                                    setSearchedBuildings([...data[1]])
                                    setFilteredBuildings([...data[1]])
                                    setDisplayedBuildings([...data[1]])
                                },
                                function(error) {}
                            )
                        }
                    }
                    setBuildings(data[1])
                    setSearchedBuildings(data[1])
                    setFilteredBuildings(data[1])
                    setDisplayedBuildings(data[1])
                })
                setFilters({ //set all filters to true so alerts show when first loading page
                    "Disabled Access" : false,
                    "Printer": false,
                    "Computer" : false,
                    "fakeFilter" : false
                })
        }
        makeFunctionCall()
    },[orgId])

    

    useEffect(() => {
        buildingSection = document.getElementById("buildingSection");
        if (buildingSection != null){
            buildingSection.scrollTop = scrollAmount;
            console.log(buildingSection.scrollAmount)
        }
    },[hoveredBuilding])

    /* eslint-enable */

    function toggleFake(){
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const pos = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                };
                console.log(pos,"pos")
                dist = pos;
                setCenter(pos)
                
              },
              () => {
                console.log("idk");
              }
            );
        }
        let newBuildings = [...buildings]
        if (center != null){
            for (const i in newBuildings){
                let distPromise = calculateAndDisplayRoute(directionsService,center,newBuildings[i][1],newBuildings[i][4])
                distPromise.then(
                    function(value) {
                        console.log(value,"val")
                        newBuildings[i].push(value)
                    },
                    function(error) {console.log("thing broke")}
                )
                
            }
            setBuildings([...newBuildings])
            setSearchedBuildings([...newBuildings])
            setFilteredBuildings([...newBuildings])
            setDisplayedBuildings([...newBuildings])
        }

    }
    const handleChange = e => {
        setSearchQuery(e.target.value);
        let tempBuildings = []
        for (const i in filteredBuildings) {
            if(filteredBuildings[i][0].toLowerCase().includes((e.target.value).toLowerCase())){
                tempBuildings.push(filteredBuildings[i])
            }
        }
        setDisplayedBuildings(tempBuildings)
        tempBuildings = []
        for (const i in buildings) {
            if(buildings[i][0].toLowerCase().includes((e.target.value).toLowerCase())){
                tempBuildings.push(buildings[i])
            }
        }
        setSearchedBuildings(tempBuildings)
    };
    function calculateAndDisplayRoute(directionsService, start, end) {
        return new Promise((resolve, reject) => {
          directionsService.route({
            origin: start,
            destination: end,
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.IMPERIAL
          }, function(response, status) {
            if (status === google.maps.DirectionsStatus.OK) {
              let dist = response.routes[0].legs[0].distance.text
              console.log(dist, typeof(dist))
              resolve(dist)
            } else {
              reject('Directions request failed due to ' + status)
            }
          });
        });
      }
    function LeftSection(){
        return(
            <div className="leftSection">
                <div className="upperSection">
                    <div className="logoSection">
                        <img className="orgLogo" src={orgInfo["orgImg"]} alt="organisation logo"/>
                        <div className="orgName">{orgInfo["orgName"]}</div>
                        <img className="otisBubbles" src={"https://occusenseimageuploads.s3.eu-west-2.amazonaws.com/occusenseImgs/otisBubbles.png"} alt="otis with bubbles"/>
                    </div>
                </div>
                <BuildingBox building={hoveredBuilding}/>
            </div>
        )
    }    
    function Map(){
        console.log(buildings[0][5],typeof(buildings[0][5]))
        console.log(buildings.length, buildings[0][5])
        let mapCenter = center
        if (mapCenter == null){
            mapCenter = {lat: 51.509865, lng: -0.118092}
        }
        for (const i in buildings){
            if (typeof(buildings[i][5]) == "string"){
                console.log("jajaja")
                buildings[i][5] = JSON.parse(buildings[i][5].replace(/lat/g, '"lat"').replace(/lng/g, '"lng"'));
            }
        }
        return (
            <div className="mapThing">
                {!isLoaded ? (
                    <h1>Loading...</h1>
                ) : (
                    <GoogleMap
                    mapContainerClassName="map-container"
                    center={mapCenter}
                    id="map"
                    zoom={10}
                    >
                        {buildings.map((building) => (
                            <MarkerF key={building[4]} position={building[5]} ></MarkerF>                         
                        ))}
                        } 
                    </GoogleMap>
                )}
                
            </div>

        )
    }
    function BuildingBox(building){
        building = building["building"]
        shownBuilding = building[4]
        console.log(building,"building")
        let comparedToUsual;
        if (building === ""){
            return(
                <div className="noHover">
                    Click on a building to see info
                </div>
            )
        }
        if (building[8] - 10 <= building[6] && building[6] <= building[8] + 10){
            comparedToUsual = "About as busy as usual"
        } else if (building[6] < building[8]){
            comparedToUsual = "Quieter than usual"
        } else if (building[6] > building[8]){
            comparedToUsual = "Busier than usual"
        } else {
            comparedToUsual = "N/A"
        }
        console.log(building)
        return(
            <div className="buildingBox shadow">
                <img className="buildingImage shadow" src={building[3]} alt="building hero"/>
                <div className="buildingText">
                    <div className="siteMetric">
                        <div className="iconDiv">
                            <FontAwesomeIcon className="metricIcon" icon={solid('layer-group')} />
                        </div>
                        {building[7]} floors
                    </div>
                    <div className="siteMetric">
                        <div className="iconDiv">
                            <FontAwesomeIcon className="metricIcon" icon={solid('location-dot')} />
                        </div>
                        {building[1].split(",")[0]}
                    </div>
                    <div className="siteMetric">
                        <div className="iconDiv">
                            <FontAwesomeIcon className="metricIcon" icon={solid('chart-line')} />
                        </div>
                        {comparedToUsual}
                    </div>
                </div>
            </div>
        )
    }
    function BuildingsSection(){
        return (
            <div id="buildingSection" className="buildingsSection scroll">
                {displayedBuildings.map((building) => { //show each table
                    return (
                        <Building building={building}/>
                    )
                })}
            </div>
        )
    }
    function setHovered(event,building){
        if (shownBuilding === building[4]){
            navigate("/building?building="+building[4])
        } else {
            event.preventDefault()
            buildingSection = document.getElementById("buildingSection");
            setScrollAmount(buildingSection.scrollTop)
            setHoveredBuilding(building)
        }
    }
    function changeFilters(filterName,filterState){ //changes which filters are active
        
        let newbuildings = []
        let newFilters = {...filters};
        for (const i in filterName){
            newFilters[filterName[i]] = filterState[i];
        }
        setFilters(newFilters)
        if (!newFilters["Disabled Access"] && !newFilters["Printer"] && !newFilters["Computer"]){
            setFilteredBuildings(buildings)
            setDisplayedBuildings(searchedBuildings)
        }
        else{
            for (const i in searchedBuildings){
                let x = searchedBuildings[i][9];
                for (const j in x){
                    if (newFilters[x[j]]){
                        newbuildings.push(searchedBuildings[i])
                    } else {
                        console.log(x[j],newFilters[x[j]])
                    }
                }
            }
            setDisplayedBuildings(newbuildings)
            newbuildings = []
            for (const i in buildings){
                let x = buildings[i][9];
                for (const j in x){
                    if (newFilters[x[j]]){
                        newbuildings.push(buildings[i])
                    }
                }
            }
            setFilteredBuildings(newbuildings)
        }
    }
    function Building(building){
        building=building["building"]
        let buildingColour;
        if (building[6] < 33){
            buildingColour = "green"
        } else if (building[6] < 67) {
            buildingColour = "orange"
        } else {
            buildingColour = "red"
        }

        return(
            <div className={`building shadow ${buildingColour}`} id={building[0]} onClick={(event) => setHovered(event,building)}>
                <div className="buildingInfo">
                    <div className="siteNameDistance">
                        <div className='siteName'>
                            {building[0]}
                        </div>
                        <div id={building[4]} className='siteDistance'>
                            {building[10]}
                        </div>
                    </div>
                    <div className='tablesFree'>
                        {100-building[6]}% avaliable
                    </div>
                </div>
                <div className="buildingButton">
                    <FontAwesomeIcon className="buildingAngle" icon={solid('angle-right')} />
                </div>
            </div>
        )
    }
    return(
        <div style={{backgroundColor: "white", minHeight: "100vh"}}>
            <SlidingPane //side panel
                isOpen={paneOpened}
                title="Filters"
                width="250px"
                className='filtersPanel'
                overlayClassName='filtersPanel'
                onRequestClose={() => {
                    // triggered on "<" on left top click or on outside click
                    openPane(false);
                }}
                >
                <div className='typeFilters'>
                    <div className='filter'>
                        <Checkbox toggle defaultChecked={filters["Disabled Access"]} checked={filters["Disabled Access"]} onChange={ () =>
                            changeFilters(["Disabled Access"],[!(filters["Disabled Access"])])
                        }/>
                        Disabled Access
                    </div>
                    <div className='filter'>
                        <Checkbox toggle defaultChecked={filters["Printer"]} checked={filters["Printer"]} onChange={ () =>
                            changeFilters(["Printer"],[!(filters["Printer"])])
                        }/>
                        Printer
                    </div>
                    <div className='filter'>
                        <Checkbox toggle defaultChecked={filters["Computer"]} checked={filters["Computer"]} onChange={ () =>
                            changeFilters(["Computer"],[!(filters["Computer"])])
                        }/>
                        Computer
                    </div>
                </div>
            </SlidingPane>
            <div className="main">
                <LeftSection/>
                <div className="rightSection">
                    <div className="buildingSearchFilters">
                        <div className="searchSection">
                            <input 
                                type="input" 
                                id="location" 
                                placeholder="Search"
                                className="buildingSearch"
                                value={searchQuery}
                                onChange={handleChange}
                            />
                            <button className='locationButton' onClick={() => toggleFake()}><FontAwesomeIcon icon={solid('location-crosshairs')} className="locationCrosshair" /></button>
                        </div>
                        <Popup trigger={<button className='mapButton'><FontAwesomeIcon icon={solid('map-location-dot')} className="locationCrosshair"/></button>} position="center" modal><Map/></Popup>
                        <button className='mapButton' onClick={() => openPane(true)}><FontAwesomeIcon icon={solid('filter')} className='locationCrosshair'/></button>
                    </div>
                    <BuildingsSection/>
                </div>
            </div>
            <div className="footer"/>
        </div>     
    )
}