import './FloorPlanEditor__TextInput.css';
import { useEffect, useRef } from 'react';


export const FloorPlanEditor__TextInput = ({label="", value="50", style={}, className="", emptyValue="", onChange=(newValue) => {}}) => {
    const inputRef = useRef();

    useEffect(() => {
        inputRef.current.value=value?value:emptyValue;
    }, [value, emptyValue])

    return (
        <div className={`FloorPlanEditor__TextInput ${className?className:""}`} style={style}>
            <p className="FloorPlanEditor__TextInput__label">{label}</p>
            <input ref={inputRef} onChange={(e) => {onChange(e.target.value)}} type="text" className="FloorPlanEditor__TextInput__input" defaultValue={value?value:emptyValue} onKeyDown={(e) => {e.stopPropagation()}}/>
        </div>
    )
}
