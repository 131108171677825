import { ResizableBox } from 'react-resizable';
import "./resize.css";
import "./tables.css";
import { ItemTypes } from './constants';
import { useDrag } from 'react-dnd';
import { useState, useCallback, useEffect, useRef } from 'react';
import { useDrop, useDragLayer } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend'

function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
        return v.toString(16);
    });
}

function getStyles(left, top, isDragging) {
    return {
        position: 'absolute',
        // IE fallback: hide the real node using CSS when dragging
        // because IE will ignore our custom "empty image" drag preview.
        opacity: isDragging ? 0 : 1,
        height: isDragging ? 0 : '',
    }
}

const Table = ({id, left, top, tables, setEdges, onTableClick=(e)=>{}, onDragging=(id)=>{}, onTableSizeChange=(id, width, height) => {}, selected=false, width=45, height=45, snapLeft, snapRight, snapTop, snapBottom}) => {
    const [ref, setRef] = useState(null);
    const [shiftPressed, setShiftPressed] = useState(false);
    const [size, setSize] = useState({x: width, y:height});

    const resizableBoxRef = useRef();

    const [{isDragging}, drag, preview] = useDrag(() => ({
        type: ItemTypes.TABLE,
        item: { id, left, top, draggedComponent: ref},
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    }), [ref])

    function dragStart(e){
        getEdges();
        onDragging(id)
    }

    function getEdges(){
        let edges = {x:[], y:[]};
        for (let tableId of Object.keys(tables)){
            if (tableId !== id){
                edges.x.push(tables[tableId].left)
                edges.x.push(tables[tableId].left + tables[tableId].width)

                edges.y.push(tables[tableId].top)
                edges.y.push(tables[tableId].top + tables[tableId].height)
            }
        }
        setEdges(edges)
    }

    function onResize(e) {
        setShiftPressed(e.shiftKey)
        
        function round5(x){
            return Math.ceil(x/5)*5;
        }

        if (shiftPressed){
            if(resizableBoxRef.current.state.width>resizableBoxRef.current.state.height){
                resizableBoxRef.current.state.width = resizableBoxRef.current.state.height
            }else{
                resizableBoxRef.current.state.height = resizableBoxRef.current.state.width
            }
        }

        resizableBoxRef.current.state.width = round5(resizableBoxRef.current.state.width)
        resizableBoxRef.current.state.height = round5(resizableBoxRef.current.state.height)

        setSize({x: resizableBoxRef.current.state.width, y: resizableBoxRef.current.state.height});

        onTableSizeChange(id, resizableBoxRef.current.state.width, resizableBoxRef.current.state.height)
    }

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true })
    }, [preview])

    return (
        <div 
            css-snap-top={snapTop}
            css-snap-bottom={snapBottom}
            css-snap-right={snapRight}
            css-snap-left={snapLeft}
            onDragStart={dragStart} 
            onDragEnd={getEdges} 
            onClick={(e) => {onTableClick(e); e.stopPropagation();}} 
            className={`table ${selected?"selected":""}`} 
            key={id} 
            id={id} 
            style={Object.assign({left:left, top:top, cursor: "grab"}, getStyles(left, top, isDragging))}
        >
            <div>
                <ResizableBox ref={resizableBoxRef} width={width} height={height} draggableOpts={{grid: [5, 5]}}
                minConstraints={[45, 45]} maxConstraints={[300, 300]} onResizeStart={onResize} onResize={(e) => {onResize(e)}} lockAspectRatio={shiftPressed}>
                    <div css-size={`${size.x} x ${size.y}`} className="table-container" ref={(div) => {drag(div);setRef(div);}}>
                        <div className="table-temp"></div>
                    </div>
                </ResizableBox>
            </div>
        </div>
    )
}

const ToolboxTable = ({id, left, top}) => {
    /* eslint-disable no-unused-vars */
    const [{isDragging}, drag, preview] = useDrag(() => ({
        type: ItemTypes.TABLE_TOOLBOX,
        item: { id, left, top },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    }))
    /* eslint-enable no-unused-vars */

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true })
    }, [preview])

    return (
        <div className='table_preview' key={id} id={id} style={{left:left, top:top}}>
            <div className='table-container' ref={drag}></div>
        </div>
    )
}

const Map = ({tables, setTables, addTable, setEdges, snapDistance, edges, selectedTables, onTableClick=(e, id)=>{}, onBackgroundClick=()=>{}, onDragging=(id)=>{}, onTableSizeChange=(id, width, height)=>{}}) => {

    const mapElement = useRef(null);

    function closestDigit(x, digits) {
        let closest = digits[0];
        let diff = Math.abs(x - closest);
        
        for (let i = 1; i < digits.length; i++) {
        const currentDiff = Math.abs(x - digits[i]);
        if (currentDiff < diff) {
            closest = digits[i];
            diff = currentDiff;
        }
        }
        
        return [closest, diff];
    }

    const moveTable = useCallback(
        (id, left, top) => {

            let tmpTable = tables[id]

            let x = tables[id].left + left
            let y = tables[id].top + top


            //x
            if(edges.x.length !== 0){
                let [closest, diff] = closestDigit(x, edges.x);
                let [closestToWidth, widthDiff] = closestDigit(x + tmpTable.width, edges.x);

                if(diff < widthDiff){
                    if(diff <= snapDistance){
                        x = closest
                    } 
                } else {
                    if(widthDiff <= snapDistance){
                        x = closestToWidth - parseInt(tmpTable.width)
                    }
                }
                
            }

            //y
            if(edges.y.length !== 0){
                let [closest, diff] = closestDigit(y, edges.y);
                let [closestToHeight, heightDiff] = closestDigit(y + tmpTable.height, edges.y);

                if(diff < heightDiff){
                    if(diff <= snapDistance){
                        y = closest
                    } 
                } else {
                    if(heightDiff <= snapDistance){
                        y = closestToHeight - tmpTable.height
                    }
                }
            }

            
            tmpTable.top = y
            tmpTable.left = x
            tables[id] = tmpTable
            setTables({...tables})
        },
        [tables, edges.x, edges.y, setTables, snapDistance],
    )

    const [, drop] = useDrop(
        () => ({
            accept: [ItemTypes.TABLE, ItemTypes.TABLE_TOOLBOX],
            drop(item, monitor) {

                const element = mapElement.current.children[0];
                const rect = element.getBoundingClientRect();
                const x = rect.left;
                const y = rect.top;

                switch (monitor.getItemType()){
                    case ItemTypes.TABLE:
                        const delta = monitor.getDifferenceFromInitialOffset()
                        let left = Math.round(delta.x)
                        let top = Math.round(delta.y)
                        moveTable(item.id, left, top)
                        return undefined
                    case ItemTypes.TABLE_TOOLBOX:
                        const toolbox_delta = monitor.getSourceClientOffset()
                        addTable({left:toolbox_delta.x - x, top:toolbox_delta.y - y})
                        return undefined
                    default:
                        return undefined;
                }
                
            },
        }),
        [moveTable],
    )

    return (
        <div ref={mapElement}>
            <div className='map' ref={drop} onClick={()=>{onBackgroundClick()}}>
                {
                    Object.keys(tables).map((key)=>{
                        return(<Table 
                                selected={selectedTables.indexOf(key) !== -1} 
                                onDragging={(e) => {onDragging(e)}} 
                                onTableClick={(e)=>{onTableClick(e, key)}} 
                                id={key} 
                                key={key} 
                                tables={tables}
                                setEdges={setEdges}
                                onTableSizeChange={(id, width, height) => {onTableSizeChange(id, width, height)}}
                                {...tables[key]}></Table>)
                    })
                }
            </div>
        </div>    
    )
}

const CustomDragLayer = ({edges, snapDistance}) => {
   
    const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }))

    if(item == null){
        return null;
    }

    let local_snaps = {left:false, right:false, top:false, bottom:false};

    const layerStyles = {
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 100,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
    }
   

    let width = 0;
    let height = 0;

    if(itemType === ItemTypes.TABLE){
        if (item != null){
            width = item.draggedComponent.parentElement.style.width
            height = item.draggedComponent.parentElement.style.height
        }
    }

    function getItemStyles(initialOffset, currentOffset) {

        function closestDigit(x, digits) {
          let closest = digits[0];
          let diff = Math.abs(x - closest);
          
          for (let i = 1; i < digits.length; i++) {
            const currentDiff = Math.abs(x - digits[i]);
            if (currentDiff < diff) {
              closest = digits[i];
              diff = currentDiff;
            }
          }
          
          return [closest, diff];
        }

        

        if (!initialOffset || !currentOffset) {
            return {
            display: 'none',
            }
        }
        let { x, y } = currentOffset

        //x
        if(edges.x.length !== 0){
            let [closest, diff] = closestDigit(currentOffset.x, edges.x);
            let [closestToWidth, widthDiff] = closestDigit(currentOffset.x + parseInt(width), edges.x);

            if(diff === widthDiff && diff <= snapDistance){
                local_snaps.left = true
                local_snaps.right = true
            }

            if(diff < widthDiff){
                if(diff <= snapDistance){
                    x = closest
                    local_snaps.left = true
                } 
            } else {
                if(widthDiff <= snapDistance){
                    x = closestToWidth - parseInt(width)
                    local_snaps.right = true
                }
            }
             
        }

        //y
        if(edges.y.length !== 0){
            let [closest, diff] = closestDigit(currentOffset.y, edges.y);
            let [closestToHeight, heightDiff] = closestDigit(currentOffset.y + parseInt(height), edges.y);

            if(diff === heightDiff && diff <= snapDistance){
                local_snaps.top = true
                local_snaps.bottom = true
            }

            if(diff < heightDiff){
                if(diff <= snapDistance){
                    y = closest
                    local_snaps.top = true
                } 
            } else {
                if(heightDiff <= snapDistance){
                    y = closestToHeight - parseInt(height)
                    local_snaps.bottom = true
                }
            }
        }

        const transform = `translate(${x}px, ${y}px)`
        return {
            transform,
            WebkitTransform: transform,
        }
            
    }

    function renderItem() {

        switch (itemType) {
            case ItemTypes.TABLE:
                return <Table 
                            width={parseInt(width)} 
                            height={parseInt(height)} 
                            selected={true}
                            snapRight={local_snaps.right?"true":"false"}
                            snapLeft={local_snaps.left?"true":"false"}
                            snapTop={local_snaps.top?"true":"false"}
                            snapBottom={local_snaps.bottom?"true":"false"}
                        />
            case ItemTypes.TABLE_TOOLBOX:
                return <Table width={45} height={45} selected/>
            default:
                return null
        }
    }

    if (!isDragging) {
        return null
    }

    return (
        <div style={layerStyles}>
            <div style={getItemStyles(initialOffset, currentOffset)}>
                {renderItem()}
            </div>
        </div>
    )
}

export const FloorTest = () => {
    const [tables, setTables] = useState({})
    const [selectedTables, setSelectedTables] = useState([])
    const [edges, setEdges] = useState({x:[], y:[]});

    const snapDistance = 10;

    const deleteSelectedTables = useCallback(() => {
        for (let tableId of Object.keys(tables)){
            if(selectedTables.indexOf(tableId) !== -1){
                delete tables[tableId]
            }
        }
        setTables({...tables})
    }, [tables, selectedTables]);

    useEffect(()=>{
        document.onkeydown = (e) => {
            switch(e.key){
                case "Backspace":
                    deleteSelectedTables();
                    break;
                case "Delete":
                    deleteSelectedTables();
                    break;
                default:
                    break;
            }
        };
    }, [tables, selectedTables, deleteSelectedTables])
    

    function addTable({top=0, left=0}){
        tables[uuid()] = {top:top, left:left, width: 45, height:45}
        setTables({...tables})
    }

    const selectTable = ({e={}, id}) => {
        if (e.shiftKey) {
            if (selectedTables.indexOf(id) === -1) {
                selectedTables.push(id);
                setSelectedTables([...selectedTables]);
            } else {
                let toRemoveIndex = selectedTables.indexOf(id);
                selectedTables.splice(toRemoveIndex, 1); 
                setSelectedTables([...selectedTables]);
            }
        } else {
            setSelectedTables([id]);
        }

    }

    function updateTableSize(id, width, height){
        tables[id].height = height
        tables[id].width = width
        setTables({...tables})
    }

    function deselectAllTables() {
        setSelectedTables([]);
    }

    return (
        <>
            <div style={{width:"fit-content"}}>
                <Map 
                    onDragging={(id) => {selectTable({id})}} 
                    onBackgroundClick={() => {deselectAllTables()}} 
                    onTableClick={(e, id) => {selectTable({e, id})}} 
                    selectedTables={selectedTables} 
                    tables={tables} 
                    setTables={setTables} 
                    addTable={addTable}
                    onTableSizeChange={(id, width, height)=>{updateTableSize(id, width, height)}}
                    setEdges={setEdges}
                    edges={edges}
                    snapDistance={snapDistance}
                ></Map>
                <CustomDragLayer 
                    edges={edges}
                    snapDistance={snapDistance}
                />
            </div>
            <div className='toolbar'>
                <div style={{position:"relative", marginLeft:"10px", height:"45px", width:"45px"}}>
                    {/* Have to have 2 here to avoid flashing on drag */}
                    <ToolboxTable id={uuid()} left={0} top={0}/>
                </div>
            </div>
        </>
    )
}