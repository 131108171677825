import './Login.css';
import '../../fonts.css';
import React, { useState } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import LoginComponent from './loginComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

export const Login = () => {
    let navigate = useNavigate()
    const {search}  = useLocation();
    const parameters = new URLSearchParams(search);

    // URL must contain an organisation parameter of an existing organisation
    const orgId = parameters.get('organisation');
    const [availableSSO, setAvailableSSO] = useState(null)
    const [organisationName, setOrganisationName] = useState(null)

    // We verify that a query for the login page includes an organisation
    if (orgId === null || orgId === ""){
        // If no organisation has been included, users will be taken to an error page.
        // The redirect should include an error message to explain what has gone wrong to the user.
        navigate('/404', {state:{error: "No organisation included."}});
    }

    // A function to take a user back to the landing page
    const returnHome = () => {
        navigate('/')
    }

    // Functional component for all of the login information
    function LoginSection(){
        return (
            <div className='loginPageContent'>
                <div className='loginContentBox shadow'>
                    <div className='backContainerDesktop' onClick={() => returnHome()}>
                        <div className="backArrowDesktop">
                            <FontAwesomeIcon icon={solid('angle-left')} />
                        </div>
                        <p className='backTextDesktop'>Back</p>
                    </div>
                    <header className='loginPageHeader'>
                        <h1>OccuSense</h1>
                        <img className="loginHeroImg" alt="OccuSense octopus logo" src="https://occusenseimageuploads.s3.eu-west-2.amazonaws.com/occusenseImgs/OccuSenseLogo.png"/>
                        <h2>{organisationName} Login</h2>
                        <p>Sign-in with a just a tap below to get started!</p>
                    </header>
                    <div className='loginForm'>
                        {availableSSO === 'microsoft' ? (
                            <button className='loginButton shadow'>Sign in with Microsoft</button>      
                        ) : <GoogleOAuthProvider clientId="858645520459-2m788ir4esrt7f8935n9ltgsfkmmlrn7.apps.googleusercontent.com">
                                <React.StrictMode>
                                    <LoginComponent/>
                                </React.StrictMode>
                            </GoogleOAuthProvider>}            
                    </div>
                </div> 
            </div>
        )
    }

    // Make a database call to validate which organisation data to display to the user.
    useEffect(()=>{
        async function makeFunctionCall(){
            // This fetches data using an API route defined in main.py taking the queried organiation as a
            // parameter.
            fetch(`${process.env.REACT_APP_API_URL}/login?organisation=`+String(orgId))
            .then(response =>
                // Convert the API response to JSON. 
                response.json()
            )
            .then(response => {
                // Check the returned JSON found an organisation name associated with the queried ID.
                if(response.orgName !== "null"){
                    // Set state variable tracking available form of sign-in to the type offered by the 
                    // returned organisation.
                    setAvailableSSO(response.orgSSO)

                    // Set the variable tracking the name of the organisation to the associated name from the database.
                    setOrganisationName(response.orgName) 
                }else{
                    // If an organisation name could not be found in the DB relating to the ID, take the user
                    // to an error page and include the error message explaining.
                    navigate('/404',{state:{error:"Organisation not found."}})
                }
                   
            }) 
        }
        makeFunctionCall();
        
    }, [navigate, orgId]);

    
    // Return the login section component.
    return (
        <main>
            <LoginSection/>
        </main>     
    )
}
